import { useLanguageCode } from "@src/utils/hooks/useLanguageCode";
import { useMemo } from "react";

const rootRoute = (languageCode = ":languageCode") => `/${languageCode}`;

export const ROUTES = {
    Root: rootRoute(),
    VehicleDetails: (languageCode = ":languageCode", handle = ":handle") =>
        `${rootRoute(languageCode)}/vehicles/${handle}`,
    UsedVehicleDetails: (languageCode = ":languageCode", handle = ":handle") =>
        `${rootRoute(languageCode)}/used-vehicles/${handle}`,
    Vehicles: (languageCode = ":languageCode") => `${rootRoute(languageCode)}/vehicles`,
    UsedVehicles: (languageCode = ":languageCode") => `${rootRoute(languageCode)}/used-vehicles`,
    NearbyVehicles: (languageCode = ":languageCode") => `${rootRoute(languageCode)}/nearby`,
    ChargingStations: (languageCode = ":languageCode") => `${rootRoute(languageCode)}/charging-stations`,
    Dealers: (languageCode = ":languageCode") => `${rootRoute(languageCode)}/dealers`,
    CompareVehicles: (languageCode = ":languageCode") => `${rootRoute(languageCode)}/compare-vehicles`,
    CompareUsedVehicles: (languageCode = ":languageCode") => `${rootRoute(languageCode)}/compare-used-vehicles`,
    Incentives: (languageCode = ":languageCode") => `${rootRoute(languageCode)}/incentives`,
    HomeChargers: (languageCode = ":languageCode") => `${rootRoute(languageCode)}/home-chargers`,
};

export const useApplicationRoutes = () => {
    const languageCode = useLanguageCode();
    return useMemo(
        () => ({
            Root: rootRoute(languageCode),
            VehicleDetails: (handle = ":handle") => ROUTES.VehicleDetails(languageCode, handle),
            UsedVehicleDetails: (handle = ":handle") => ROUTES.UsedVehicleDetails(languageCode, handle),
            Vehicles: ROUTES.Vehicles(languageCode),
            UsedVehicles: ROUTES.UsedVehicles(languageCode),
            NearbyVehicles: ROUTES.NearbyVehicles(languageCode),
            ChargingStations: ROUTES.ChargingStations(languageCode),
            Dealers: ROUTES.Dealers(languageCode),
            CompareVehicles: ROUTES.CompareVehicles(languageCode),
            CompareUsedVehicles: ROUTES.CompareUsedVehicles(languageCode),
            Incentives: ROUTES.Incentives(languageCode),
            HomeChargers: ROUTES.HomeChargers(languageCode),
        }),
        [languageCode],
    );
};

export const SVCE_WEBSITE_BASE = "https://svcleanenergy.org";
