import type { Incentive, VehicleIncentive } from "@src/types";
import { useUserPrefs } from "@src/context/userPrefs/useUserPrefs";
import { calcFilteredIncentives } from "@src/calculations/incentives/calcFilteredIncentives";

export type { FilteredIncentive } from "@src/calculations/incentives/calcFilteredIncentives";

export default function useFilteredIncentives(initialIncentives: (Incentive | VehicleIncentive)[]) {
    const {
        userPrefs: { ownership },
    } = useUserPrefs();

    return calcFilteredIncentives(initialIncentives, {
        ownership,
    });
}
