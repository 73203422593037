export const DEFAULT_GOOGLE_MAP_CENTER = {
    lat: 37.7916858,
    lng: -122.397855,
};
export const DEFAULT_GOOGLE_MAP_ZOOM = 12;
// export const K_HOVER_DISTANCE = 30;

export const GOOGLE_MAPS_API_KEY = (() => {
    if (import.meta.env.MODE === "production") {
        if (window.location.hostname === "svce.ev.d.zappyride.com") {
            return (
                import.meta.env.VITE_APP_STAGING_GOOGLE_MAP_API_KEY || import.meta.env.VITE_GOOGLE_MAPS_API_KEY || ""
            );
        } else {
            return import.meta.env.VITE_APP_PROD_GOOGLE_MAP_API_KEY || import.meta.env.VITE_GOOGLE_MAPS_API_KEY || "";
        }
    } else if (import.meta.env.MODE === "staging") {
        return import.meta.env.VITE_APP_STAGING_GOOGLE_MAP_API_KEY || import.meta.env.VITE_GOOGLE_MAPS_API_KEY || "";
    } else {
        return import.meta.env.VITE_APP_DEV_GOOGLE_MAP_API_KEY || import.meta.env.VITE_GOOGLE_MAPS_API_KEY || "";
    }
})();
