import { Box, Typography } from "@mui/material";
import { useIntl } from "@utils/hooks/useIntl";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import Button from "../button/Button";

import { useUserPrefs } from "@src/context/userPrefs/useUserPrefs";

function ErrorFallback(): JSX.Element {
    const { formatMessage } = useIntl();
    const { resetUserPrefs } = useUserPrefs();

    return (
        <Box width="100%" minHeight="100vh" display="flex" flexDirection="column">
            <Box flex="1 1" textAlign="center" maxWidth="800px" mx="auto" p="40px">
                <Box role="alert">
                    <Typography variant="h1" component={"h1"}>
                        {formatMessage({ id: "error.boundary.header" })}
                    </Typography>
                    <Typography>{formatMessage({ id: "error.boundary.body" })}</Typography>
                    <Box mt="40px">
                        <Button
                            onClick={() => {
                                resetUserPrefs();
                                window.location.reload();
                            }}
                        >
                            {formatMessage({ id: "error.boundary.resetButton" })}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default function ErrorBoundary({ children }: { children: React.ReactNode }): React.ReactNode {
    return (
        <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={console.error}>
            {children}
        </ReactErrorBoundary>
    );
}
