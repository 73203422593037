import { transformLanguageToApi } from "@src/constants/apiLanguages";

import { useLanguageCode } from "@src/utils/hooks/useLanguageCode";
import { logDev } from "@src/utils/logDev";
import { difference } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { IntlProvider } from "react-intl";
import { LanguageChoiceContext } from "./intl/LanguageChoiceContext";
import { fetchTranslations } from "./reactIntlConfig";

/**
 * Get the messages for a given language. If any messages are
 * missing, merge them in from the fallback language.
 *
 * An array of the ids that had to be filled in is also
 * returned.
 */
const getMessages = (messages: Record<string, any>, languageCode: string, fallbackLanguageCode: string) => {
    if (!messages || !Object.keys(messages).length) {
        return {
            messages: {},
            missingIds: [],
        };
    }

    if (!messages?.[languageCode]) {
        return {
            messages: messages[fallbackLanguageCode],
            missingIds: Object.keys(messages[fallbackLanguageCode]),
        };
    } else {
        return {
            messages: { ...messages[fallbackLanguageCode], ...messages[languageCode] },
            missingIds: difference(Object.keys(messages[fallbackLanguageCode]), Object.keys(messages[languageCode])),
        };
    }
};

/**
 * Get an object of all the keys mapped to... the same keys.
 */
const getMessageCodes = (messages: Record<string, any>, langauge: string) => {
    return {
        messages: Object.keys(messages[langauge]).reduce((codes, key) => ({ ...codes, [key]: key }), {}),
        missingIds: [],
    };
};

interface Props {
    children: React.ReactNode;
    defaultLanguage?: string;
}

export default function ReactIntlProvider({ children, defaultLanguage = "en" }: Props) {
    const languageCode = useLanguageCode();

    const zrLanguage = transformLanguageToApi(languageCode);

    const [messages, setMessages] = useState<Record<string, any>>({});
    useEffect(() => {
        logDev("ReactIntlProvider.useEffect.languageCode -", languageCode);
        localStorage.setItem("language", languageCode);
        document.getElementsByTagName("html")[0].lang = languageCode;
        fetchTranslations(languageCode).then(setMessages);
    }, [languageCode]);

    const { messages: currentMessages, missingIds } = useMemo(
        () =>
            languageCode === "xx"
                ? getMessageCodes(messages, defaultLanguage)
                : getMessages(messages, languageCode, defaultLanguage),
        [messages, languageCode, defaultLanguage],
    );

    const value = useMemo(
        () => ({ language: languageCode, zrLanguage, missingIds, defaultLanguage }),
        [defaultLanguage, languageCode, missingIds, zrLanguage],
    );

    if (!messages || !Object.keys(messages).length) return null;

    return (
        <LanguageChoiceContext.Provider value={value}>
            <IntlProvider locale={languageCode} key={languageCode} messages={currentMessages}>
                {children}
            </IntlProvider>
        </LanguageChoiceContext.Provider>
    );
}
