import { Box, Grid2, Grow, LinearProgress, Typography } from "@mui/material";
import ButtonLink from "@src/components/buttonLink";
import SectionHighlight from "@src/components/sectionHighlight";
import { ROUTES } from "@src/constants/routes";
import { useIntl } from "@src/utils/hooks/useIntl";
import { lazy, Suspense } from "react";

const VehicleSlider = lazy(() => import("./vehicleSlider"));

export default function HomeRebrandVehicles({ vehiclesInView }: { vehiclesInView: boolean }) {
    const { formatMessage } = useIntl();

    const vehicles = {
        title: formatMessage({ id: "screens.home.vehicles.title" }),
        text: formatMessage({ id: "screens.home.vehicles.text" }),
        button: formatMessage({ id: "screens.home.vehicles.button" }),
    };

    return (
        <Grow in={vehiclesInView}>
            <Box sx={{ width: "100%", mb: 3 }}>
                <SectionHighlight
                    variant={"secondary"}
                    title={vehicles.title}
                    titleSx={{ paddingTop: 3 }}
                    textSx={{ mx: 0, maxWidth: "100%" }}
                    hideDivider
                    childrenStackSx={{ alignItems: "center" }}
                    description={
                        <>
                            <Grid2 size={{ xs: 12, md: 8 }} sx={{ my: { xs: 2, md: 0 } }}>
                                <Typography component={"p"} sx={{ lineHeight: "25px" }}>
                                    {vehicles.text}
                                </Typography>
                            </Grid2>
                            <Grid2 size={{ xs: 12, md: 4 }} sx={{ textAlign: { xs: "left", md: "right" } }}>
                                <ButtonLink variant="contained" to={ROUTES.Vehicles}>
                                    {vehicles.button}
                                </ButtonLink>
                            </Grid2>
                        </>
                    }
                >
                    <Suspense fallback={<LinearProgress />}>
                        <VehicleSlider />
                    </Suspense>
                </SectionHighlight>
            </Box>
        </Grow>
    );
}
