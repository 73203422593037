import { useUserGeolocation } from "@src/api/hooks/useUserGeolocation";
import { useCallback, useEffect, useMemo, useState } from "react";
import { USER_PREF_KEY, USER_PREF_PRESETS } from "../../constants/userPrefs";
import type { UserPrefs } from "../../types/userPrefs";
import { UserPrefsContext } from "./UserPrefsContext";

type HistoricalUserPrefs = UserPrefs & {
    // Add new keys to this section as optional
    milesDrivenAnnually?: number;
};

/**
 * upgrade() is run when userPrefs are loaded from
 * localStorage, on page load. Make any adjustments
 * to the data that are necessary for old saved data
 * to work with new code changes.
 */
const upgrade = (userPrefs: HistoricalUserPrefs) => {
    if (!userPrefs.milesDrivenAnnually) {
        userPrefs.milesDrivenAnnually = USER_PREF_PRESETS.milesDrivenAnnually;
    }
    if ("electricityProvider" in userPrefs) {
        delete userPrefs.electricityProvider;
    }
    return userPrefs;
};

interface Props {
    children: JSX.Element;
}

const getSavedUserPrefs = (): UserPrefs => {
    const stored = localStorage.getItem(USER_PREF_KEY);
    return stored ? upgrade(JSON.parse(stored)) : USER_PREF_PRESETS;
};

/**
 * If there are any changes between two values,
 * we'll detect them.
 *
 * Two objects are the same if all of their
 * values are the same, recursively.
 */
const detectChanges = function <T>(newT: T, oldT: T): boolean {
    if (newT && oldT && typeof newT === "object" && typeof oldT === "object") {
        // First check for arrays that changed length.
        // Without this special check, a shorter newT
        // would look like the same value to the code
        // that follows.
        if ("length" in newT && "length" in oldT && newT.length !== oldT.length) {
            return true;
        }
        return (Object.keys(newT) as (keyof T)[]).some((key) => detectChanges(newT[key], oldT[key]));
    } else {
        return newT !== oldT;
    }
};

/**
 * Mutators have the chance to check values being
 * submitted to setUserPrefs and change the
 * changes.
 *
 * A mutator must accept a Partial<UserPrefs>
 * and a UserPrefs as parameters and return a
 * Parital<UserPrefs>.
 *
 * The results of one mutator are fed into the
 * next one, in the order given.
 *
 * Example return values:
 *
 *  Return: { ...newPrefs, zipcode: '' }
 *  Effect: The given changes are applied with one
 *          extra change
 *
 *  Return: newPrefs
 *  Effect: Only the given changes are applied
 *
 *  Return: {}
 *  Effect: All changes are eliminated
 *
 *  Return: { zipcode: '' }
 *  Effect: Only change the `zipcode` field,
 *          eliminating all other changes
 *
 *  Return: const { zipcode, ...theRest }; return theRest;
 *  Effect: Remove the `zipcode` field from the
 *          changes, but keep all other changes
 */
const mutators: ((newPrefs: Partial<UserPrefs>, oldPrefs: UserPrefs) => Partial<UserPrefs>)[] = [
    (newPrefs: Partial<UserPrefs>, oldPrefs: UserPrefs) => {
        // We want to hold onto a known good zipcode. If
        // we are replacing one that had no errors, then
        // let's keep it.
        // Either way we need to clear the error flag so
        // that we know to check again (elsewhere).
        if (newPrefs.zipcode && newPrefs.zipcode !== oldPrefs.zipcode) {
            if (oldPrefs.zipcodeError === true) {
                return { zipcodeError: false, ...newPrefs };
            } else {
                return {
                    zipcodeError: false,
                    zipcodeLastValid: oldPrefs.zipcode,
                    ...newPrefs,
                };
            }
        } else {
            return newPrefs;
        }
    },
    (newPrefs: Partial<UserPrefs>, _oldPrefs: UserPrefs) => {
        // The zipcode has been updated. Remember that.
        if (newPrefs.zipcode) {
            return { ...newPrefs, zipcodeIsDefault: false };
        } else {
            return newPrefs;
        }
    },
];

export default function UserPrefsProvider({ children }: Props) {
    const [userPrefs, _setUserPrefs] = useState<UserPrefs>(getSavedUserPrefs());

    const setUserPrefs = useCallback(
        (prefChanges: Partial<UserPrefs> | ((prefChanges_: UserPrefs) => Partial<UserPrefs>)) => {
            _setUserPrefs((currentUserPrefs) => {
                const changes: Partial<UserPrefs> =
                    typeof prefChanges === "function" ? prefChanges(currentUserPrefs) : prefChanges;

                if (!detectChanges(changes, currentUserPrefs)) {
                    return currentUserPrefs;
                }

                const newPrefs = mutators.reduce((changes_, mutator) => mutator(changes_, currentUserPrefs), changes);

                return { ...currentUserPrefs, ...newPrefs };
            });
        },
        [],
    );

    const resetUserPrefs = useCallback(() => {
        _setUserPrefs(USER_PREF_PRESETS);
    }, []);

    const { userLocation } = useUserGeolocation();
    useEffect(() => {
        if (userLocation) {
            setUserPrefs({ geolocation: userLocation });
        }
    }, [setUserPrefs, userLocation]);

    useEffect(() => {
        if (userPrefs) {
            localStorage.setItem(USER_PREF_KEY, JSON.stringify(userPrefs));
        }
    }, [userPrefs]);

    const value = useMemo(
        () => ({ userPrefs, setUserPrefs, resetUserPrefs }),
        [userPrefs, setUserPrefs, resetUserPrefs],
    );

    return <UserPrefsContext.Provider value={value}>{children}</UserPrefsContext.Provider>;
}
