/**
 * The app uses a set of simplified codes to identify
 * languages. These codes are compatible with browsers
 * but not always compatible with the web API.
 *
 * This file provides functions to transform back and
 * forth. Any language not supported by the API is
 * treated as English.
 */

export const EN = null;

const ES = "ES";

const ZH = "zh-Hant";

export type ApiLanguage = typeof EN | typeof ES | typeof ZH;

const languageMap: Record<string, ApiLanguage> = {
    en: EN,
    es: ES,
    zh: ZH,
};

export const transformLanguageToApi = (lang: string) => {
    return languageMap[lang] || EN;
};

export const transformApiToLanguage = (lang: ApiLanguage) => {
    return Object.entries(languageMap).find(([_key, value]) => value === lang)![0] || "en";
};
