import { USER_PREF_PRESETS } from "@src/constants/userPrefs";
import type { UserPrefs } from "@src/types";
import { createContext } from "react";

interface UserPrefsContextType {
    userPrefs: UserPrefs;
    setUserPrefs: (prefChange: Partial<UserPrefs> | ((prefChanges: UserPrefs) => Partial<UserPrefs>)) => void;
    resetUserPrefs: () => void;
}

export const UserPrefsContext = createContext<UserPrefsContextType>({
    userPrefs: USER_PREF_PRESETS,
    setUserPrefs: (_newPrefs) => null,
    resetUserPrefs: () => null,
});
