import { SVCE_WEBSITE_BASE } from "@src/constants/routes";

export const MIDDLE_TOOLBAR_LINKS = [
    {
        title: "header.middle.0.main",
        items: [
            {
                title: "header.middle.0.story",
                href: `${SVCE_WEBSITE_BASE}/about/`,
            },
            {
                title: "header.middle.0.who",
                href: "whoweare",
                items: [
                    {
                        title: "header.middle.0.board",
                        href: `${SVCE_WEBSITE_BASE}/board/`,
                    },
                    {
                        title: "header.middle.0.staff",
                        href: `${SVCE_WEBSITE_BASE}/team/`,
                    },
                    {
                        title: "header.middle.0.join",
                        href: `${SVCE_WEBSITE_BASE}/jobs/`,
                    },
                ],
            },
            {
                title: "header.middle.0.area",
                href: `${SVCE_WEBSITE_BASE}/service-area/`,
            },
            {
                title: "header.middle.0.how",
                href: `${SVCE_WEBSITE_BASE}/how-it-works/`,
            },
            {
                title: "header.middle.0.projects",
                href: `${SVCE_WEBSITE_BASE}/power-sources/`,
            },
        ],
    },
    {
        title: "header.middle.1.main",
        items: [
            {
                title: "header.middle.1.residents",
                href: `${SVCE_WEBSITE_BASE}/ehub/`,
                items: [
                    {
                        title: "header.middle.1.why",
                        href: "https://ehub.svcleanenergy.org/",
                    },
                    {
                        title: "header.middle.1.main",
                        href: "https://ehub.svcleanenergy.org/incentives",
                    },
                    {
                        title: "header.middle.1.advice",
                        href: "https://goelectric.svcleanenergy.org/",
                    },
                ],
            },
            {
                title: "header.middle.1.businessesAndContractors",
                href: `${SVCE_WEBSITE_BASE}/offers-services/`,
                items: [
                    {
                        title: "header.middle.1.main",
                        href: "https://ehub.svcleanenergy.org/business",
                    },
                    {
                        title: "header.middle.1.contractorTraining",
                        href: "https://svcleanenergy.org/contractor-training/",
                    },
                ],
            },
        ],
    },
    {
        title: "header.middle.2.main",
        items: [
            {
                title: "header.middle.2.plans",
                href: `${SVCE_WEBSITE_BASE}/plans/`,
            },
            {
                title: "header.middle.2.rates",
                href: `${SVCE_WEBSITE_BASE}/electricity-rates/`,
                items: [
                    {
                        title: "header.middle.2.residents",
                        href: "https://svcleanenergy.org/residential-rates/",
                    },
                    {
                        title: "header.middle.2.businesses",
                        href: "https://svcleanenergy.org/commercial-rates/",
                    },
                    {
                        title: "header.middle.2.rooftopSolar",
                        href: "https://svcleanenergy.org/solar/",
                    },
                ],
            },
            {
                title: "header.middle.2.bill",
                href: `${SVCE_WEBSITE_BASE}/your-bill/`,
            },
            {
                title: "header.middle.2.assistance",
                href: `${SVCE_WEBSITE_BASE}/payment-assistance/`,
            },
            {
                title: "header.middle.2.toc",
                href: `${SVCE_WEBSITE_BASE}/conditions/`,
            },
        ],
    },
];
