import { ELanguage, LANGUAGE_CODES } from "@src/types";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const browserLanguage = navigator.language?.replace(/-.*/, "") as ELanguage;

export const useLanguageCode = (): ELanguage => {
    // NOTE: For some reason, useParams does NOT work, the `code` value returned can be undefined
    // const code = useParams().languageCode as ELanguage;
    const code = (useLocation().pathname?.slice(1, 3) || ELanguage.ENGLISH) as ELanguage;
    const windowCode = window.location.pathname.slice(0, 3).replace("/", "") as ELanguage;
    const storedCode = localStorage.getItem("language") as ELanguage | null;

    return useMemo(() => {
        if (LANGUAGE_CODES.includes(code)) {
            return code;
        } else if (LANGUAGE_CODES.includes(windowCode)) {
            return windowCode;
        } else if (storedCode && LANGUAGE_CODES.includes(storedCode)) {
            return storedCode;
        } else if (LANGUAGE_CODES.includes(browserLanguage)) {
            return browserLanguage;
        } else {
            return ELanguage.ENGLISH;
        }
    }, [windowCode, code, storedCode]);
};
