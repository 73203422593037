import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function useScrollToTop() {
    const location = useLocation();

    useEffect(() => {
        const scroller = document.getElementById("root");
        scroller?.scrollTo?.(0, 0);
    }, [location.pathname]);
}
