import { Box, Grid2, Stack, Typography, Palette, SxProps, Theme, Divider } from "@mui/material";

interface Props {
    imageUrl?: string;
    imageAlt?: string;
    imageSide?: "left" | "right";
    imagePadded?: boolean;
    title: React.ReactNode | React.ReactNode[];
    description?: React.ReactNode | React.ReactNode[];
    children?: React.ReactNode;
    variant?: "primary" | "secondary" | "tertiary" | "dark" | ("primary" | "secondary" | "tertiary" | "dark")[];

    hideDivider?: boolean;
    containerSx?: SxProps<Theme>;
    rowSx?: SxProps<Theme>;
    textContainerSx?: SxProps<Theme>;
    imageSx?: SxProps<Theme>;
    titleSx?: SxProps<Theme>;
    textSx?: SxProps<Theme>;
    childrenStackSx?: SxProps<Theme>;
    leftGridSx?: SxProps<Theme>;
    rightGridSx?: SxProps<Theme>;
    background?: string;
}

const SectionHighlight: React.FC<Props> = ({
    imageUrl,
    imageAlt = "",
    imageSide = "right",
    variant = "primary",
    imagePadded = false,
    title,
    description,
    background,
    hideDivider = false,

    containerSx,
    rowSx: _rowSx,
    textContainerSx,
    imageSx,
    titleSx,
    textSx,
    childrenStackSx,
    leftGridSx,
    rightGridSx,

    children,
}) => {
    const dark = variant === "dark" || variant.includes("dark");
    const secondary = variant === "secondary" || variant.includes("secondary");
    const tertiary = variant === "tertiary" || variant.includes("tertiary");
    const right = imageSide === "right";
    const left = imageSide === "left";

    const content = (
        <Box
            className="section-highlight-content-container"
            sx={Object.assign(
                {},
                {
                    zIndex: 2,
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: imageUrl ? "center" : "flex-start",
                    alignItems: "center",
                },
                textContainerSx,
            )}
        >
            <Stack
                direction={"column"}
                gap={"1.5em"}
                zIndex={2}
                className="section-highlight-content-stack"
                sx={{ width: "100%" }}
            >
                {!hideDivider && (
                    <Divider
                        sx={{
                            width: "250px",
                            borderWidth: 5,
                            backgroundColor: "primary.main",
                            borderColor: "primary.main",
                        }}
                    />
                )}
                <Grid2 container className="section-highlight-content-grid">
                    <Grid2 size={12} className="section-highlight-title-container">
                        <Typography
                            variant={"h1"}
                            component={secondary ? "h2" : "h1"}
                            sx={{ mb: 2, ...titleSx }}
                            className="section-highlight-title"
                        >
                            {title}
                        </Typography>
                    </Grid2>
                    {typeof description === "string" ? (
                        <Grid2 size={12}>
                            <Typography component={"span"} sx={textSx}>
                                {description}
                            </Typography>
                        </Grid2>
                    ) : (
                        description
                    )}
                </Grid2>
            </Stack>
        </Box>
    );

    const image = imageUrl && (
        <Box
            className="section-highlight-img"
            zIndex={2}
            component="img"
            src={imageUrl}
            alt={imageAlt}
            sx={{
                height: "100%",
                maxHeight: "700px",
                maxWidth: "100%",
                display: { xs: "none", lg: "unset" },
                objectFit: "cover",
                padding: imagePadded ? "4rem" : 0,
                zIndex: 2,
                borderTopRightRadius: right ? (t) => t.spacing(5) : undefined,
                borderTopLeftRadius: left ? (t) => t.spacing(5) : undefined,
                ...imageSx,
            }}
        />
    );

    const getBackground = ({ palette }: { palette: Palette }) => {
        if (background) {
            return background;
        } else if (dark) {
            return palette.grey[900];
        } else if (secondary) {
            if (imageUrl) {
                return "primary.main";
            }
            return palette.white.main;
        } else if (tertiary) {
            return palette.white.main;
        }
        return "transparent";
    };

    return (
        <Grid2
            container
            className="section-hightlight-grid-container"
            spacing={{ xs: 3, lg: 0 }}
            sx={{
                zIndex: 2,
                color: dark ? "white.main" : undefined,
                background: getBackground,
                marginTop: { xs: 5, lg: imageUrl ? 0 : 5 },
                ...containerSx,
            }}
        >
            <Grid2
                size={{ xs: 12, lg: imageUrl ? 6 : 12 }}
                className="section-hightlight-grid-left"
                sx={{
                    zIndex: 2,
                    position: "relative",
                    textAlign: left ? "left" : undefined,
                    px: { xs: left ? 0 : 2, md: left ? 0 : 10 },
                    ...leftGridSx,
                }}
            >
                {left ? image : content}
            </Grid2>
            <Grid2
                size={{ xs: 12, lg: imageUrl ? 6 : 12 }}
                className="section-hightlight-grid-right"
                sx={{
                    zIndex: 2,
                    position: "relative",
                    textAlign: right ? "right" : undefined,
                    px: { xs: right ? 0 : 2, md: right ? 0 : 10 },
                    ...rightGridSx,
                }}
            >
                {right ? image : content}
            </Grid2>
            <Grid2
                size={{ xs: 12 }}
                position={"relative"}
                sx={{ px: { xs: 2, md: 10 } }}
                className="section-hightlight-grid-children-container"
            >
                <Stack
                    direction={"column"}
                    gap={"1.5em"}
                    zIndex={2}
                    sx={childrenStackSx}
                    className="section-hightlight-grid-children-stack"
                >
                    {children}
                </Stack>
            </Grid2>
        </Grid2>
    );
};

export default SectionHighlight;
