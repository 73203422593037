import { type ApiDealer, type Dealer } from "./dealer";
import { type Incentive } from "./incentive";
import type { ApiVehicleIncentive, VehicleIncentive } from "./vehicleIncentive";

export type VehicleTypeType = "electric" | "gas" | "nearby";

export type TVehicleStatusCondition = "new" | "used" | "upcoming";

export enum EVehicleDrivetrain {
    ALL = "All",
    FWD = "Front-Wheel Drive",
    FOUR_WD = "4-Wheel Drive",
    AWD = "All-Wheel Drive",
    RWD = "Rear-Wheel Drive",
}

export interface VehicleImage {
    altText: string;
    urlFull: string;
    urlThumbnail: string;
}

export interface Vehicle {
    id: string;
    msrp: number;
    displayName: string;
    modelYear: number;
    make: string;
    model: string;
    type: string;
    subtype: string;
    trim?: string;
    fossilFuelEfficiency: number | null;
    fuelType: "BEV" | "PHEV" | "GAS";
    // This field isn't defined by the API at
    // all, but it allows us to send extra
    // display information, when desired
    subtitle?: string;
}

export type EquivalentFossilVehicle = Vehicle & {
    epaId: string;
    fuelType: "GAS";
    fossilFuelEfficiency: number;
};

export type ElectricVehicle = Vehicle & {
    language: string;
    fuelType: "BEV" | "PHEV";
    handle: string;
    modelId: string;
    batteryCapacity: number;
    acChargingPower: number;
    dcChargingPower: number | null;
    doors: number;
    seatsMin: number;
    seatsMax: number;
    cargoVolumeMin: number;
    cargoVolumeMax: number;
    acceleration: number;
    officialWebsiteUrl: string;
    electricRange: number;
    totalRange: number;
    electricEfficiency: number;
    drivetrain: EVehicleDrivetrain;
    curbWeight: number;
    images: VehicleImage[];
    incentives: VehicleIncentive[];
    equivalentGasVehicle: EquivalentFossilVehicle | null;
    status: TVehicleStatusCondition;
    gvwr: number | null;
    horsepower: number | null;
    towingCapacity: number | null;
    plugTypes: string[];
    customPrice: number | null;
};

export const isElectricVehicle = (vehicle?: Vehicle): vehicle is ElectricVehicle => {
    return Boolean(vehicle && vehicle.fuelType !== "GAS");
};

export const isBEV = (vehicle?: Vehicle): vehicle is ElectricVehicle => {
    return Boolean(vehicle && vehicle.fuelType === "BEV");
};

export const isPHEV = (vehicle?: Vehicle): vehicle is ElectricVehicle => {
    return Boolean(vehicle && vehicle.fuelType === "PHEV");
};

export type GasVehicle = Vehicle & {
    epaId: string;
    fuelType: "GAS";
    handle: string;
    doors: number;
    seatsMin: number;
    seatsMax: number;
    cargoVolumeMin: number;
    cargoVolumeMax: number;
    acceleration: number;
    officialWebsiteUrl: string;
    totalRange: number;
    drivetrain: string;
    curbWeight: number;
    images: VehicleImage[];
    incentives: VehicleIncentive[];
};

export const isGasVehicle = (vehicle?: Vehicle): vehicle is GasVehicle => {
    return Boolean(vehicle && vehicle.fuelType === "GAS");
};

export interface ApiElectricVehicle {
    vehicle_id?: string;
    electric_vehicle_id: number;
    fossil_fuel_vehicle_id: number;
    locally_available: number;
    available_for_purchase_as_new: boolean;
    handle: string;
    epa_id: number;
    fuel_id: number;
    make: string;
    model: string;
    trim: string;
    display_name: string;
    fuel: "BEV" | "PHEV";
    model_year: number;
    type: string;
    form_factor: string;
    msrp: number;
    custom_price: number | null;
    battery_capacity: number;
    ac_charging_power: number;
    dc_charging_power: number | null;
    doors: number;
    seats_min: number;
    seats_max: number;
    cargo_volume_min: number;
    cargo_volume_max: number;
    acceleration: number;
    official_website_url: string;
    eligible_for_ca_rebate: string;
    eligible_for_ca_hov: number;
    san_joaquin_valley_ev_rebate: number;
    electric_range: number;
    total_range: number;
    electric_efficiency: number;
    fossil_fuel_efficiency: number | null;
    availability: string;
    drivetrain: EVehicleDrivetrain;
    curb_weight: number;
    incentives: ApiVehicleIncentive[];
    images: {
        position: number;
        alt: string;
        url_full: string;
        url_thumbnail: string | null;
    }[];
    video_reviews: [];
    equivalent_gas_vehicle: {
        epa_id: number;
        make: string;
        model: string;
        model_year: number;
        msrp: number;
        fossil_fuel_efficiency: number;
        picture: number;
    } | null;
    gvwr: number | null;
    horsepower: number | null;
    towing_capacity: number | null;
    plug_types: string[];
}

export interface ApiGasVehicle {
    vehicle_id?: string;
    electric_vehicle_id: number;
    fossil_fuel_vehicle_id: number;
    locally_available: number;
    available_for_purchase_as_new: boolean;
    handle: string;
    epa_id: number;
    fuel_id: number;
    make: string;
    model: string;
    trim: string;
    display_name: string;
    fuel: "gas";
    model_year: number;
    type: string;
    form_factor: string;
    msrp: number;
    custom_price: number | null;
    battery_capacity: number;
    ac_charging_power: number;
    dc_charging_power: number | null;
    doors: number;
    seats_min: number;
    seats_max: number;
    cargo_volume_min: number;
    cargo_volume_max: number;
    acceleration: number;
    official_website_url: string;
    eligible_for_ca_rebate: string;
    eligible_for_ca_hov: number;
    san_joaquin_valley_ev_rebate: number;
    electric_range: number;
    total_range: number;
    electric_efficiency: number;
    fossil_fuel_efficiency: number | null;
    availability: string;
    drivetrain: string;
    curb_weight: number;
    incentives: ApiVehicleIncentive[];
    images: {
        position: number;
        alt: string;
        url_full: string;
        url_thumbnail: string | null;
    }[];
    video_reviews: [];
    equivalent_gas_vehicle: {
        epa_id: number;
        make: string;
        model: string;
        model_year: number;
        msrp: number;
        fossil_fuel_efficiency: number;
        picture: number;
    };
}

export type ApiNearbyVehicle = Omit<
    ApiElectricVehicle,
    | "vehicle_id"
    | "electric_vehicle_id"
    | "handle"
    | "type"
    | "display_name"
    | "doors"
    | "curb_weight"
    | "gvwr"
    | "horsepower"
    | "towing_capacity"
    | "plug_types"
> & {
    incentives: ApiVehicleIncentive[];
    dealer: ApiDealer;
    vehicle_dealership_vin: string;
    vehicle_dealership_condition_new: boolean;
    vehicle_dealership_price: string;
    vehicle_dealership_url: string;
    vehicle_dealership_images: string[];
    vehicle_dealership_id: number;
};

export type NearbyVehicle = Omit<
    ElectricVehicle,
    "id" | "type" | "doors" | "curbWeight" | "gvwr" | "horsepower" | "towingCapacity" | "plugTypes"
> & {
    incentives: Incentive[];
    dealer: Dealer;
    vehicleDealershipVin: string;
    vehicleDealershipConditionNew: boolean;
    vehicleDealershipPrice: string;
    vehicleDealershipUrl: string;
    vehicleDealershipImages: string[];
    vehicleDealershipId: number;
    distanceMiles: number;
};
