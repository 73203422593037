import { useIntl } from "@src/utils/hooks/useIntl";
import { useIsMobilePhone } from "@src/utils/hooks/useIsMobilePhone";
import React, { useMemo } from "react";

const BottomToolbarTitle = ({
    icon,
    id,
    iconSide,
}: {
    icon: React.ReactNode;
    id: string;
    iconSide: "left" | "right";
}) => {
    const isMobilePhone = useIsMobilePhone();
    const { formatMessageString } = useIntl();

    const title = useMemo(() => {
        if (isMobilePhone && typeof id === "string" && id.endsWith(".main")) {
            return id.replace(".main", ".mobile.main");
        } else {
            return id;
        }
    }, [id, isMobilePhone]);

    return (
        <>
            {iconSide === "left" && icon}
            {isMobilePhone && (!icon || iconSide === "left") ? null : " " + formatMessageString({ id: title })}
            {iconSide === "right" && icon}
        </>
    );
};

export default BottomToolbarTitle;
