import Box from "@mui/material/Box";
import { useIntl } from "@utils/hooks/useIntl";

const Loading = ({ height = "100%", width = "100%" }: { height?: string; width?: string }) => {
    const { formatMessageString } = useIntl();
    return (
        <Box
            sx={{
                height,
                width,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <img src="/loading.svg" alt={formatMessageString({ id: "common.loading" })} style={{ maxWidth: 100 }} />
        </Box>
    );
};

export default Loading;
