import { logDev } from "@src/utils/logDev";
import { type TranslationType } from "../constants/translations";
import { ELanguage } from "@src/types";

import en from "@src/constants/translation_languages/en";

const es = import(`../../src/constants/translation_languages/es`);
const vi = import(`../../src/constants/translation_languages/vi`);
const zh = import(`../../src/constants/translation_languages/zh`);

// @ts-expect-error - xx language code missing
const imports: Record<ELanguage, Promise<Record<string, any>>> = {
    en: Promise.resolve(en),
    es,
    vi,
    zh,
};

// From https://formatjs.io/docs/react-intl/upgrade-guide-2x/#flatten-messages-object
function flattenTranslations(nestedMessages: TranslationType, prefix = "") {
    return Object.keys(nestedMessages).reduce((msgs: Record<string, string>, key) => {
        const value = nestedMessages[key];
        const prefixedKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value === "string") {
            msgs[prefixedKey] = value;
        } else if (value === null) {
            // react-intl will not allow empty strings so we have to put a space for translations set to null
            // https://github.com/formatjs/formatjs/issues/607
            msgs[prefixedKey] = " ";
        } else {
            Object.assign(msgs, flattenTranslations(value, prefixedKey));
        }

        return msgs;
    }, {});
}

export const fetchTranslations = (languageCode: ELanguage = ELanguage.ENGLISH): Promise<Record<string, any>> => {
    logDev("fetchTranslations.languageCode", languageCode);

    return imports[languageCode]
        .then((imported) => {
            if (languageCode === "en") {
                return {
                    [languageCode]: flattenTranslations(en.translation),
                };
            } else {
                return {
                    en: flattenTranslations(en.translation),
                    [languageCode]: flattenTranslations(imported.default.translation),
                };
            }
        })
        .catch((e) => {
            console.error(e);
            return imports.en
                .then((english) => {
                    return { en: flattenTranslations(english.default.translation) };
                })
                .catch((ex) => {
                    console.error(ex);
                    return {} as Record<string, any>;
                });
        });
};
