import LaunchIcon from "@mui/icons-material/Launch";
import ExternalLink from "@src/components/externalLink";
import { useLanguage } from "@src/context/intl/useLanguage";
import { useCallback } from "react";
import { useIntl as useIntlBase } from "react-intl";
import ReactMarkdown from "react-markdown";
import { NavLink } from "react-router-dom";

type Components = Parameters<typeof ReactMarkdown>[0]["components"];

const markdown = (md: string, components: Components): JSX.Element => {
    return (
        <ReactMarkdown
            components={{
                a: (props) => {
                    const href = props.href || "";
                    const linkIsAbsolute = href.startsWith("http");
                    const domainIsDifferent = linkIsAbsolute && new URL(href).host !== location.host;
                    return linkIsAbsolute ? (
                        <ExternalLink href={href} target="_blank">
                            {props.children}
                            {domainIsDifferent && (
                                <LaunchIcon
                                    sx={{
                                        fontSize: "1em",
                                        color: "primary",
                                        verticalAlign: "middle",
                                        marginLeft: "2px",
                                    }}
                                />
                            )}
                        </ExternalLink>
                    ) : (
                        <NavLink to={href}>{props.children}</NavLink>
                    );
                },
                ...components,
            }}
        >
            {md}
        </ReactMarkdown>
    );
};

interface IUseIntlProps {
    markdownComponents?: Components;
}

export function useIntl({ markdownComponents = {} }: IUseIntlProps = {}) {
    const { missingIds, defaultLanguage } = useLanguage();
    const intl = useIntlBase();
    const { formatMessage: formatMessageString } = intl;

    /**
     * The base useIntl knows all the messages for the current
     * language ReactIntlProvider pulled them from
     * translations.ts.
     *
     * However, in ReactIntlProvider, we may have found that
     * some messages are missing from the current language. If
     * so those particular messages have been filled in from
     * English, the default language.
     *
     * `missingIds` is a list of the messages that had to be
     * filled in with copy from English.
     *
     * For all messages that are in the user's chosen language,
     * we want formatMessage to have its default behavior. But
     * in cases of missing ids, the result of formatMessage
     * needs to be wrapped in an HTML element with a `lang`
     * attribute indicating that screen readers should use
     * their English voices.
     *
     * The functions below use addLang() to provide that
     * functionality.
     */
    const addLang = useCallback(
        (
            formatMessageParams: Parameters<typeof formatMessageString>,
            formatted: React.ReactNode | React.ReactNode[],
        ): React.ReactNode => {
            const { id } = formatMessageParams[0];
            if (id && formatted && missingIds.includes(id)) {
                return <bdi lang={defaultLanguage}>{formatted}</bdi>;
            } else {
                return <span>{formatted}</span>;
            }
        },
        [defaultLanguage, missingIds],
    );

    /**
     * This works like the original formatMessage, but runs
     * the results through addLang().
     */
    const formatMessage = useCallback(
        (...formatMessageParams: Parameters<typeof formatMessageString>) => {
            return addLang(formatMessageParams, formatMessageString(...formatMessageParams));
        },
        [addLang, formatMessageString],
    );

    /**
     * This function assumes the message is in Markdown format.
     *
     * It also runs the results through addLang().
     */
    const formatMessageMd = useCallback(
        (...formatMessageParams: Parameters<typeof formatMessage>) => {
            const md = formatMessageString(...formatMessageParams);
            const formatted = typeof md === "string" ? markdown(md, markdownComponents) : md;
            return addLang(formatMessageParams, formatted);
        },
        [addLang, formatMessageString, markdownComponents],
    );

    return {
        ...intl,
        formatMessage,
        formatMessageMd,
        formatMessageString,
    };
}
