import type { GasVehicle, ElectricVehicle, NearbyVehicle, VehicleTypeType } from "@src/types";
import { createContext } from "react";

interface VehicleCacheContextType {
    gasVehicles: GasVehicle[] | undefined;
    electricVehicles: ElectricVehicle[] | undefined;
    nearbyVehicles: NearbyVehicle[] | undefined;
    status: "idle" | "loading" | "error";
    error: string | undefined;
    fetchVehicles: (
        fetchFunction: () => Promise<ElectricVehicle[] | GasVehicle[] | NearbyVehicle[] | undefined>,
        vehicleType: VehicleTypeType,
        cacheKey?: string,
    ) => void;
}

export const VehicleCacheContext = createContext<VehicleCacheContextType>({
    gasVehicles: undefined,
    electricVehicles: undefined,
    nearbyVehicles: undefined,
    status: "loading",
    error: undefined,
    fetchVehicles: () => null,
});
