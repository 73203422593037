export enum ETaxFilingStatus {
    SINGLE = "single",
    JOINT = "joint",
    HEAD_OF_HOUSEHOLD = "head_of_household",
}

export interface ApiIncentive {
    id: number;
    applicable_to_fcevs: boolean;
    applicable_to_new_vehicles: boolean;
    applicable_to_used_vehicles: boolean;
    applicant_type: string;
    available: boolean;
    description: string;
    details_url: string;
    grantor: string;
    grantor_type: string;
    income_dependent: boolean;
    limitations: string | null;
    max_amount: number;
    name: string;
    point_of_sale: boolean;
    support_for: string;
    support_subtype: string[];
    type: string;
    typical_amount: string;
}

export interface Incentive {
    zrId: number;
    language: string;
    grantor: string;
    grantorType: string;
    applicantType: string;
    name: string;
    description: string;
    type: string;
    supportFor: string;
    typicalAmount: string;
    limitations: string | null;
    detailsUrl: string;
    applicableToNewVehicles: boolean;
    applicableToUsedVehicles: boolean;
    incomeDependent: boolean;
    applicableToFcevs: boolean;
    available: boolean;
    maxAmount: number;
    pointOfSale: boolean;
    supportSubtype: string[];
}
