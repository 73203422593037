export enum ELanguage {
    ENGLISH = "en",
    CHINESE = "zh",
    SPANISH = "es",
    VIETNAMESE = "vi",
    TESTING = "xx",
}

export const LANGUAGE_CODES = [
    ELanguage.ENGLISH,
    ELanguage.CHINESE,
    ELanguage.SPANISH,
    ELanguage.VIETNAMESE,
    ELanguage.TESTING,
];
