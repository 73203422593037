import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

const UUID_KEY = "uuid";

const getUuid = () => {
    let uuid = localStorage.getItem(UUID_KEY);
    if (uuid) return uuid;

    uuid = uuidv4();
    localStorage.setItem(UUID_KEY, uuid);
    return uuid;
};

export const useUuid = () => {
    const [uuid] = useState<string>(getUuid());

    return { uuid };
};
