import { Box, Grow, LinearProgress, Stack, Typography } from "@mui/material";
import chargerInCar from "@src/assets/images/chargerInCar.jpg";
import homeCTABackground from "@src/assets/images/home-cta-background.jpg";
import womanChargingCarWithDog from "@src/assets/images/woman-charging-car-with-dog.png";
import Accordion from "@src/components/accordion";
import ButtonLink from "@src/components/buttonLink";
import Screen from "@src/components/screen";
import SectionHighlight from "@src/components/sectionHighlight";
import { ROUTES, useApplicationRoutes } from "@src/constants/routes";
import { useIntl } from "@src/utils/hooks/useIntl";
import { lazy, Suspense, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import VehicleComparisonBar from "../vehicleList/components/comparison/VehicleComparisonBar";
import HomeRebrandAnchors from "./components/anchors/HomeRebrandAnchors";
import HomeRebrandVehicles from "./components/HomeRebrandVehicles";
import { EHomeAnchor } from "./constants/anchors";
import FAQ_ACCORDIONS from "./constants/faq_accordions";

const IncentivesSlider = lazy(() => import("./components/incentivesSlider"));
const HomeRebrandChargerMap = lazy(() => import("./components/HomeRebrandChargerMap"));

const Home: React.FC = () => {
    const { formatMessage, formatMessageString, formatMessageMd } = useIntl();
    const routes = useApplicationRoutes();

    const hero = {
        title: formatMessageString({ id: "screens.home.hero.title" }),
        text: formatMessageString({ id: "screens.home.hero.text" }),
        button: formatMessageString({ id: "screens.home.hero.button" }),
    };

    const save = {
        title: formatMessageString({ id: "screens.home.save.title" }),
        text1: formatMessageMd({ id: "screens.home.save.text.1" }),
        text2: formatMessageMd({ id: "screens.home.save.text.2" }),
        button: formatMessageString({ id: "screens.home.save.button" }),
    };

    const discover = {
        title: formatMessageString({ id: "screens.home.discover.title" }),
        button: formatMessageString({ id: "screens.home.discover.button" }),
    };

    const faq = {
        title: formatMessageString({ id: "screens.home.faq.title" }),
        text: formatMessageMd({ id: "screens.home.faq.text" }),
    };

    const contact = {
        title: formatMessageString({ id: "screens.home.contact.title" }),
        text: formatMessageString({ id: "screens.home.contact.text" }),
        button: formatMessageString({ id: "screens.home.contact.button" }),
    };

    const [discoverInViewRef, discoverInView] = useInView({ triggerOnce: true });
    const [saveInViewRef, saveInView] = useInView({ triggerOnce: true });
    const [mapInViewRef, mapInView] = useInView({ triggerOnce: true });
    const [contactUsInViewRef, contactUsInView] = useInView({ triggerOnce: true });
    const [faqInViewRef, faqInView] = useInView({ triggerOnce: true });

    const vehiclesAnchorRef = useRef<HTMLElement | null>(null);
    const discoverAnchorRef = useRef<HTMLElement | null>(null);
    const mapAnchorRef = useRef<HTMLElement | null>(null);
    const faqAnchorRef = useRef<HTMLElement | null>(null);

    const { hash: anchor } = useLocation();
    useEffect(() => {
        const ref = {
            [EHomeAnchor.CHARGING_MAP]: mapAnchorRef,
            [EHomeAnchor.DISCOVER]: discoverAnchorRef,
            [EHomeAnchor.FAQ]: faqAnchorRef,
            [EHomeAnchor.VEHICLES]: vehiclesAnchorRef,
        }[anchor as EHomeAnchor];

        if (ref) {
            ref?.current?.scrollIntoView?.({
                block: "start",
                inline: "nearest",
                behavior: "smooth",
            });
        }
    }, [anchor]);

    return (
        <Screen>
            <Box sx={{ mb: { xs: 1, lg: 5 } }} className="home-rebrand-hero">
                <SectionHighlight
                    variant={"primary"}
                    title={hero.title}
                    titleSx={{ mb: 1 }}
                    textSx={{
                        fontWeight: { xs: 500, sm: 600 },
                        fontSize: { xs: "17px", sm: "18px" },
                        lineHeight: "24.12px",
                    }}
                    description={hero.text}
                    imageUrl={chargerInCar}
                    imageSx={{ borderBottomLeftRadius: (t) => t.spacing(5) }}
                ></SectionHighlight>
            </Box>

            <Box sx={{ mb: { xs: 1, lg: 5 }, width: "100%" }}>
                <HomeRebrandAnchors />
            </Box>

            {/* Vehicle Carousel */}
            <Box className="home-rebrand-vehiclesAnchorRef" sx={{ height: 0 }} ref={vehiclesAnchorRef}>
                &nbsp;
            </Box>
            <HomeRebrandVehicles vehiclesInView={true} />

            {/* Discover */}
            <Box className="home-rebrand-discoverAnchorRef" sx={{ height: 0 }} ref={discoverAnchorRef}>
                &nbsp;
            </Box>
            <Box className="home-rebrand-discoverInViewRef" sx={{ height: 0 }} ref={discoverInViewRef}>
                &nbsp;
            </Box>
            <Suspense fallback={<LinearProgress />}>
                <Grow in={discoverInView}>
                    <Box sx={{ mb: { xs: 1, lg: 5 }, backgroundColor: "blue.main" }}>
                        <SectionHighlight
                            variant={"secondary"}
                            title={discover.title}
                            titleSx={{ color: "common.white", mb: 3 }}
                            textSx={{ mx: 0, maxWidth: "100%" }}
                            imageUrl="forces-grid-6-in-title"
                            containerSx={{ py: { xs: 4, lg: 8 } }}
                            textContainerSx={{ alignItems: "flex-start" }}
                        >
                            <IncentivesSlider />

                            <ButtonLink variant="contained" to={ROUTES.Incentives} sx={{ my: 3, mx: "auto", px: 10 }}>
                                {discover.button}
                            </ButtonLink>
                        </SectionHighlight>
                    </Box>
                </Grow>
            </Suspense>

            {/* Save */}
            <Box className="home-rebrand-saveInViewRef" sx={{ height: 0 }} ref={saveInViewRef}>
                &nbsp;
            </Box>
            <Grow in={saveInView}>
                <Box sx={{ mb: { xs: 1, lg: 5 } }}>
                    <SectionHighlight
                        variant={"secondary"}
                        title={save.title}
                        imageUrl={womanChargingCarWithDog}
                        imageSide="left"
                        imageSx={{ pl: 10 }}
                        hideDivider
                        description={
                            <Box>
                                <Typography component={"span"} sx={{ lineHeight: "28px", my: 4, display: "block" }}>
                                    {save.text1}
                                    <br />
                                    {save.text2}
                                </Typography>
                                <ButtonLink variant="contained" to={routes.HomeChargers}>
                                    {save.button}
                                </ButtonLink>
                            </Box>
                        }
                    ></SectionHighlight>
                </Box>
            </Grow>

            {/* Charging Map */}
            <Box className="home-rebrand-mapAnchorRef" sx={{ height: 0 }} ref={mapAnchorRef}>
                &nbsp;
            </Box>
            <Box className="home-rebrand-mapInViewRef" sx={{ height: 0 }} ref={mapInViewRef}>
                &nbsp;
            </Box>
            <Suspense fallback={<LinearProgress />}>
                <Grow in={mapInView}>
                    <Box sx={{ mb: { xs: 2, lg: 8 }, mt: { lg: 5 } }}>
                        <SectionHighlight
                            variant={"secondary"}
                            title={formatMessageString({ id: "screens.chargerMap.heroTitle" })}
                            titleSx={{ mb: 0 }}
                            containerSx={{ mb: 0 }}
                            childrenStackSx={{ display: { xs: "none", lg: "unset" } }}
                            rightGridSx={{ display: { xs: "none", lg: "unset" } }}
                            textSx={{ mx: 0, maxWidth: "100%" }}
                            textContainerSx={{ alignItems: "flex-start" }}
                            imageUrl="forces-grid-6-in-title"
                            hideDivider
                        ></SectionHighlight>
                        <HomeRebrandChargerMap />
                        <Box>
                            <Stack
                                direction={"column"}
                                gap={3}
                                justifyContent={"center"}
                                sx={{ px: 5, mx: "auto", maxWidth: { md: "95%" } }}
                            >
                                <Typography
                                    component={"span"}
                                    sx={{
                                        lineHeight: "28px",
                                        color: "common.black",
                                        mx: "auto",
                                        maxWidth: "100%",
                                    }}
                                >
                                    {formatMessageMd({ id: "screens.chargerMap.disclaimer" })}
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                </Grow>
            </Suspense>

            {/* FAQ */}
            <Box className="home-rebrand-faqAnchorRef" sx={{ height: 0 }} ref={faqAnchorRef}>
                &nbsp;
            </Box>
            <Box className="home-rebrand-faqInViewRef" sx={{ height: 0 }} ref={faqInViewRef}>
                &nbsp;
            </Box>
            <Grow in={faqInView}>
                <Box sx={{ mb: { xs: 1, lg: 5 } }}>
                    <Stack
                        direction={"column"}
                        gap={3}
                        justifyContent={"center"}
                        sx={{ px: 5, mx: "auto", maxWidth: { md: "70%" } }}
                    >
                        <Typography variant="h2" component={"h2"}>
                            {faq.title}
                        </Typography>
                        <Typography component={"span"}>{faq.text}</Typography>
                        {FAQ_ACCORDIONS?.map((accordion) => (
                            <Accordion
                                key={`home-faq-accordion-${accordion.key}`}
                                summary={formatMessage({
                                    id: `screens.home.faq.accordions.${accordion.key}.title`,
                                })}
                                sx={{
                                    backgroundColor: "transparent",
                                    borderTop: (t) => `1px solid ${t.palette.secondary.light}`,
                                    pt: 2,
                                    maxWidth: "100vw",
                                }}
                            >
                                {
                                    <Typography variant="body2" component={"span"} style={{ color: "black" }}>
                                        {formatMessageMd(
                                            {
                                                id: `screens.home.faq.accordions.${accordion.key}.body`,
                                            },
                                            { image: accordion.image, link: accordion.link, break: <br /> },
                                        )}
                                    </Typography>
                                }
                            </Accordion>
                        ))}
                    </Stack>
                </Box>
            </Grow>

            <Box sx={{ my: { xs: 1, lg: 5 } }}>
                <SectionHighlight
                    variant={"secondary"}
                    title={""}
                    hideDivider
                    description={
                        <Box>
                            <Typography
                                component={"span"}
                                sx={{
                                    lineHeight: "28px",
                                    color: "common.black",
                                    mx: "auto",
                                    maxWidth: "80%",
                                }}
                            >
                                {formatMessageString({ id: "screens.home.disclaimer" })}
                            </Typography>
                        </Box>
                    }
                ></SectionHighlight>
            </Box>

            {/* Call to Action */}
            <Box className="home-rebrand-contactUsInViewRef" sx={{ height: 0 }} ref={contactUsInViewRef}>
                &nbsp;
            </Box>
            <Grow in={contactUsInView}>
                <Box
                    sx={{
                        m: { xs: 0, md: 10 },
                        borderBottomLeftRadius: { xs: 40, md: 0 },
                        borderBottomRightRadius: { xs: 40, md: 0 },
                        py: 8,
                        textAlign: "center",
                        background: `linear-gradient(0deg, rgba(0, 56, 99, 0.75), rgba(0, 56, 99, 0.75)), url(${homeCTABackground})`,
                        backgroundPosition: "center",
                    }}
                >
                    <Stack direction={"column"} gap={3}>
                        <Typography variant="h2" component={"h2"} color="white">
                            {contact.title}
                        </Typography>
                        <Typography
                            component={"span"}
                            color="white"
                            fontSize={"16px"}
                            fontWeight={400}
                            sx={{ maxWidth: { xs: "80%", md: "50%" }, mx: "auto" }}
                        >
                            {contact.text}
                        </Typography>
                        <Box>
                            <ButtonLink
                                variant="contained"
                                href={"https://goelectric.svcleanenergy.org/"}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    color: "primary.main",
                                    backgroundColor: "common.white",
                                    "&:hover": {
                                        color: "common.white",
                                        backgroundColor: "primary.main",
                                    },
                                }}
                            >
                                {contact.button}
                            </ButtonLink>
                        </Box>
                    </Stack>
                </Box>
            </Grow>

            <VehicleComparisonBar />
        </Screen>
    );
};

export default Home;
