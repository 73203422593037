import { isVehicleIncentive, Ownership, type Incentive, type VehicleIncentive } from "@src/types";

export type FilteredIncentive = Incentive & {
    displayAmount: number;
};

export type FilteredVehicleIncentive = VehicleIncentive & {
    displayAmount: number;
};

const CALCULABLE_INCENTIVE_TYPES = ["REBATE", "TAX CREDIT", "TAX EXEMPTION"];

const isEligibleAndApplicable = (incentive: Incentive | VehicleIncentive, ownership: Ownership) => {
    // Regular incentives are simple
    if (!isVehicleIncentive(incentive)) {
        return incentive.available;
    }

    // Vehicle incentives must say 'eligible'
    if (incentive.evaluation.eligibility !== "eligible") {
        return false;
    }

    // Vehicle incentives should only be shown based on
    // the purchase type, cash / lease
    return ownership === "cash" ? incentive.evaluation.applicableToOwnership : incentive.evaluation.applicableToLease;
};

const buildDisplayAmount = (incentive: Incentive | VehicleIncentive) => {
    if (!CALCULABLE_INCENTIVE_TYPES.includes(incentive?.type?.toUpperCase())) {
        return 0;
    }

    // Regular incentives are simple
    if (!isVehicleIncentive(incentive)) {
        return incentive.maxAmount || 0;
    }

    return (
        (incentive.evaluation.applicableToOwnership
            ? incentive.evaluation.amountInPurchase
            : incentive.evaluation.amountInLease) || 0
    );

    // Vehicle incentives choose a field based on the
    // purchase type, cash / lease. But if those are 0
    // then they fall back to the maxAmount, like
    // regular Incentives
    // return (
    //   (ownership === 'cash'
    //     ? incentive.evaluation.amountInPurchase
    //     : incentive.evaluation.amountInLease) ||
    //   incentive.maxAmount ||
    //   0
    // );
};

export function calcFilteredIncentives(
    initialIncentives: (Incentive | VehicleIncentive)[],
    {
        ownership,
    }: {
        ownership: Ownership;
    },
) {
    const incentives: (FilteredIncentive | FilteredVehicleIncentive)[] = initialIncentives
        .filter((incentive) => isEligibleAndApplicable(incentive, ownership))
        .map((incentive) => ({
            ...incentive,
            displayAmount: buildDisplayAmount(incentive),
        }));

    const totalIncentives = incentives.reduce((sum, incentive) => sum + incentive.displayAmount, 0);

    return {
        incentives,
        totalIncentives,
    };
}
