const ENGLISH = {
    translation: {
        languageName: "English",
        header: {
            logo: "Logo",
            top: {
                meetings: "Meetings & Agendas",
                documents: "Key Documents",
            },
            middle: {
                0: {
                    main: "About Us",
                    story: "Our Story",
                    who: "Who We Are",
                    board: "Board of Directors",
                    staff: "Staff",
                    join: "Join Our Team",
                    area: "Service Area",
                    how: "How It Works",
                    projects: "Renewable Projects",
                    mobile: {
                        main: "About",
                    },
                },
                1: {
                    main: "Rebates & Offers",
                    ehub: "Get Started with eHub",
                    incentives: "Rebates & Incentives",
                    contractor: "Find A Contractor",
                    advice: "Get Advice",
                    why: "Why Go Electric?",
                    residents: "Residents",
                    businessesAndContractors: "Businesses & Contractors",
                    contractorTraining: "Contractor Training",
                    mobile: {
                        main: "Rates",
                    },
                },
                2: {
                    main: "Rates & Billing",
                    plans: "Service Plans",
                    rates: "Electricity Rates",
                    bill: "Understanding Your Bill",
                    assistance: "Payment Assistance",
                    toc: "Terms & Conditions",
                    residents: "Residents",
                    businesses: "Businesses",
                    rooftopSolar: "Rooftop Solar",
                    mobile: {
                        main: "Billing",
                    },
                },
            },
            bottom: {
                0: {
                    main: "Home",
                    home: "Home",
                },
                1: {
                    main: "Explore Electric Cars",
                    nearby: "Browse Nearby Vehicles",
                    new: "Browse New EVs",
                    used: "Browse Pre-Owned EVs",
                    compare: "Compare Vehicles",
                    dealers: "Find Dealers",
                    mobile: {
                        main: "Vehicles",
                    },
                },
                2: {
                    main: "Charging Options",
                    charging: "Locate Public Charging Stations",
                    chargers: "Shop Home EV Chargers",
                    gridshift: "GridShift: EV",
                    mobile: {
                        main: "Charging",
                    },
                },
                3: {
                    main: "Discover Incentives",
                    mobile: {
                        main: "Incentives",
                    },
                },
            },
        },

        footer: {
            contact: {
                title: "Contact Us",
            },
            moreInfo: {
                title: "More Info",
                faqs: "FAQs",
                news: "News & Articles",
                join: "Join Our Team",
                dream: "Dream Home",
            },
            community: {
                title: "For Our Community",
                agency: "Member Agency Resources",
                education: "Education, Sponsorships & Grants",
                policies: "Building Policies & Initiatives",
                transport: "Transportation Electrification",
            },
            business: {
                title: "Do Business with SVCE",
                solicitations: "Solicitations",
                diversity: "Supplier Diversity",
                media: "Media Center",
                innovation: "Innovation",
                research: "Research and Analysis",
                hive: "SVCE Data Hive",
            },
            copyright: "Copyright © 2024 Silicon Valley Clean Energy. All Rights Reserved",
            accessibility: "Accessibility",
            confidentiality: "Customer Confidentiality Policy",
        },

        common: {
            left: "Left",
            right: "Right",
            flipBack: "Flip back",
            hideGraphValues: "Hide Graph Values",
            showGraphValues: "Show Graph Values",
            filters: "Filters",
            year: "Year",
            electricVehicle: "Electric Vehicle",
            gasVehicle: "Gas Vehicle",
            electricity: "Electricity",
            gasoline: "Gasoline",
            miles: "miles",
            assumptions: "Assumptions",
            loading: "Loading",
            yes: "Yes",
            no: "No",
            all: "All",
            learnMore: "Learn More",
            cancel: "Cancel",
            compare: "Compare",
            add: "Add",
            remove: "Remove",
            clear: "Clear",
            within: "Within",
            save: "Save",
            // Unused
            any: "Any",
            notAny: "N/A",
            reset: "Reset",
        },
        time: {
            minutes: "minutes",
            minute: "minute",
            hours: "hours",
            hour: "hour",
            year: "Year",
            before: "Before",
            after: "After",
        },
        energy: {
            kwh: "kWh",
            kw: "kW",
        },
        nav: {
            openNavMenu: "Open Nav Menu",
            closeNavMenu: "Close Nav Menu",
        },
        pagination: {
            current: "Page: {page} of {totalPages}",
            next: "Next page",
            previous: "Previous page",
        },
        error: {
            header: "Error",
            details: "An error occurred while loading the page.",
            boundary: {
                header: "Please reset your inputs",
                body: "We have updated our site as part of our effort to stay up-to-date with a fast changing industry, and the inputs saved to this browser are now outdated. Please reset your inputs using the button below to resolve this issue.",
                resetButton: "Reset",
            },
        },

        charging: {
            network: "Network",
            station: "Station",
            stations: "Stations",
            chargingStations: "Charging Stations",
            costPerCharge: "Cost Per Charge",
        },

        chargers: {
            count: "{count} Chargers",
            hoursToFullCharge: "{hours} hours",
            formFactor: {
                mounted: "mounted",
                portable: "portable",
            },
            outlet: {
                hardwired: "hardwired",
            },
            socket: "socket",
            cable: "cable",
        },

        dealers: {
            dealers: "Dealers",
            contact: "Contact Dealer",
        },

        money: {
            price: "Price",
            cost: "Cost",
            max: "Max",
            min: "Min",
        },

        sorting: {
            sortBy: "Sort By",
            range: "Range",
            alphabetical: "Alphabetical",
            lowToHigh: "Low to High",
            highToLow: "High to Low",
        },

        vehicle: {
            make: "Make",
            model: "Model",
            makeLabel: "Vehicle Make",
            modelLabel: "Vehicle Model",
            yearLabel: "Vehicle Year",

            cash: "Cash",
            loan: "Loan",
            lease: "Lease",
            new: "New",
            used: "Pre-Owned",

            msrp: "MSRP",
            estimatedMsrp: "Est. MSRP",
            loanPayment: "Loan payment",
            downPayment: "Down payment",
            leasePayment: "Lease payment",
            firstLeasePayment: "First lease payment",

            leaseIncentives: "Lease incentives",
            estimatedIncentives: "Est. incentives",
            afterIncentives: "After incentives",

            type: "Type",
            subtype: "Subtype",
            gvwr: "GVWR",
            curbWeight: "Curb Weight",
            horsepower: "Horsepower",
            cargoSpace: "Cargo Space",
            payload: "Payload",
            seats: "Seats",
            towingCapacity: "Towing Ccapacity",
            fuelType: "Fuel Type",
            electricEfficiency: "Electric Efficiency",
            gasEfficiency: "Gas Efficiency",
            electricRange: "Electric Range",
            totalRange: "Total Range",
            mpge: "MPGe",
            mpg: "MPG",
            cubicFeet: "cu ft",

            drivetrain: "Drivetrain",
            location: "Location",
            condition: "Condition",
            mileage: "Mileage",
            bodyStyle: "Body Style",
            features: "Features",
            exteriorColor: "Exterior Color",

            batteryCapacity: "Battery capacity",
            maxAcChargeRate: "Max AC charge rate",
            maxDcChargeRate: "Max DC charge rate",
            level2ChargeTime: "Charge Time - Level 2",
            dcChargeTime: "Charge Time - DC",
            plugTypes: "Plug types",

            gasolineSaved: "Gasoline Saved",
            co2Reduction: "Carbon Emissions Reductions",
            treesPlanted: "Trees Planted",

            types: {
                sedan: "Sedan",
                hatchback: "Hatchback",
                coupe: "Coupe",
                minivan: "Minivan",
                wagon: "Wagon",
                truck: "Truck",
                suv: "SUV / Crossover",
            },

            fuelTypes: {
                batteryElectric: "Battery Electric",
                plugInHybrid: "Plug-in Hybrid",
                gasoline: "Gasoline",
            },

            sorting: {
                sortBy: "Sort by",
                range: "Electric Range",
                alphabetical: "Alphabetical",
                priceLow: "Price: Low to High",
                priceHigh: "Price: High to Low",
                matchScore: "Match Score",
            },
        },

        screens: {
            home: {
                disclaimer:
                    "Silicon Valley Clean Energy hosts this website as a resource for customers. SVCE does not endorse or guarantee, and makes no warranties or representations regarding, any vendor, contractor, service or product. SVCE shall not be liable for any loss or damage of any kind arising out of or connected to any vendor, contractor, service or product associated with this website. Any transactions that you enter into with a vendor, contractor or other third party are solely between you and that vendor, contractor or other third party.",
                anchors: {
                    0: "Shop Electric Vehicles",
                    1: "Discover Incentives",
                    2: "Find Charging Stations",
                    3: "FAQ",
                },
                hero: {
                    title: "Drive Electric",
                    text: "Gas vehicles cause over 50% of local air pollution. Switching to an electric vehicle (EV) cuts harmful emissions, improving air quality and public health. With rebates, tax credits, and lower charging costs, EVs make financial sense. Plus, they need less maintenance—no gas engine, smog checks, or oil changes—and many models travel 200-350 miles on a single charge.",
                    button: "Browse EVs",
                    introduction: "Introduction",
                },
                save: {
                    title: "Save on EV Charging",
                    text: {
                        1: "Save time and money with convenient home charging. Simply plug into a standard outlet and recharge 30-40 miles overnight. Need a faster charge? [Learn about charging options](https://pluginamerica.org/wp-content/uploads/2024/03/2024.02-Plug-In-America-Charging-101.pdf).",
                        2: "Take advantage of electricity discounts with an EV Rate Plan and [smart charging](https://svcleanenergy.org/gridshift-ev/). Charging at off-peak hours when electricity rates are lowest will help you save.",
                    },
                    button: "Browse Home Chargers",
                },
                vehicles: {
                    title: "New Electric Vehicles",
                    text: "Compare electric cars by EV range or price.  Click on the EV for more details, including total cost compared to a similar gas vehicle.",
                    button: "See All Cars",
                },
                discover: {
                    title: "Discover Incentives",
                    button: "Explore All Incentives",
                },
                contact: {
                    title: "Speak with an Advisor",
                    text: "Get free support from expert advisors to help you choose the right EV and find available incentives. Receive personalized assistance via email, phone, or live chat, Monday - Friday, 9 a.m. - 5 p.m.",
                    button: "Go Electric Advisor",
                },
                faq: {
                    title: "F.A.Q.",
                    text: "These are some of the most common questions we receive from the community. If you can't find something here, we have additional resources to share. [Reach out to us](https://goelectric.svcleanenergy.org/). We're here to help.",
                    accordions: {
                        1: {
                            title: "What are greenhouse gases (GHGs)?",
                            body: `
A GHG is any gaseous compound in the atmosphere that is capable of absorbing infrared radiation, thereby trapping and holding heat in the atmosphere. By increasing the heat in the atmosphere GHGs are responsible for the greenhouse effect, which ultimately leads to climate change.
{break}
{break}
The most significant GHGs are carbon dioxide, methane and nitrous oxide, according to the Environmental Protection Agency.`,
                        },
                        2: {
                            title: "What contributes to GHGs in our community?",
                            body: `
Burning fossil fuels, like gas and oil, releases carbon dioxide (CO2). In our region, about 55% of emissions come from gas-powered vehicles, and most of the remaining emissions come from burning fossil fuels in homes and buildings.*
{break}
{break}
{image}
{break}
{break}
*Sources: PG&E electricity and gas data; CARB; Metropolitan Transportation Commission; California DMV.`,
                        },
                        3: {
                            title: "Are petroleum products responsible for most of the GHG emissions?",
                            body: `
Californians are the second-largest consumer of petroleum products. In 2017, carbon dioxide (CO2) emissions from burning fossil fuels for energy were about 93% of total U.S. human-caused CO2 emissions. 
{break}
{break}
Source: U.S. Energy Information Administration, Profile Analysis, January 16, 2020.`,
                        },
                        4: {
                            title: "What can I do about climate change?",
                            body: `
As a Silicon Valley Clean Energy customer, you have already committed to reducing our region's emissions by receiving clean electricity and investing in initiatives to lower local pollution levels. If you're ready to reduce emissions even more, consider switching your gas-powered car to electric. Emissions from internal combustion engine vehicles are the number one air pollutant in Silicon Valley and across California.
{break}
{break}
From there, you can reduce emissions even further by switching to high-efficiency, electric home appliances. For example, replacing a gas-powered water heater with a heat pump water heater will eliminate about 50% of the emissions that come from your home and may save you money in the long run.       
{break}
{break}
To learn more about reducing your impact on the climate, and possibly save money as well, visit {link}, a resource funded by California state government and non-profit agencies.`,
                        },
                        5: {
                            title: "Aren't electric vehicles expensive?",
                            body: `
EVs are cheaper and easier to maintain than gas-powered cars. When you drive electric, you eliminate over two dozen mechanical components that normally require service. That means no tune-ups, no oil changes, no transmission services, and no air intake filters, spark plugs, or drive belt replacements. Perhaps best of all, no more trips to the gas station!
{break}`,
                        },
                        6: {
                            title: "How many electric vehicles are available?",
                            body: `
There are more than 200 models and trims available today and this number is quickly increasing, as major car manufacturers introduce and expand their EV lines. From family cars and SUVs to luxury sedans, there is a wide range of car manufacturers, features, and price points for you to choose from.
{break}`,
                        },
                        7: {
                            title: "Do electric vehicles go more than 200 miles on a single charge?",
                            body: `
Whether it's a quick trip to the grocery store or a long drive for a family vacation, you can drive electric anywhere from 200 to 350 miles with new EV models before requiring a charge. It has also become even easier to charge an EV from an increasing number of public charging stations, or the convenience of your own home.
{break}`,
                        },
                        8: {
                            title: "Why is it important to consider an EV when you're installing a solar + battery system?",
                            body: `
When installing solar, the size of your system should consider the additional electricity you'll use to charge your EV at home. And, when you install solar panels and a battery, the electrician evaluating your electrical panel can determine which upgrades may be required to install a charger. By installing solar panels and a battery, you can reduce your electric bill and by producing and storing your own clean electricity to use when you need it.
{break}`,
                        },
                    },
                },
            },
            chargerMap: {
                disclaimer:
                    "Mileage ranges are based upon estimates provided by EV manufacturers. Your range may vary based upon climate, region, traffic, car model, and automobile conditions. Data from the Alternative Fuel Data Center may not reflect latest availability. Please confirm availability of charging stations before commencing your journey. Submit suspected missing charging stations [here](https://afdc.energy.gov/stations#/station/new).",
                heroTitle: "Locate Charging Stations",
                heroContent:
                    "It's easier than ever to charge on the go. Enter your location to see all of the charging stations near you.",
                tabs: {
                    0: "Public Charging FAQ",
                    1: "EV Charging Speed",
                    2: "National Charging Networks",
                },
                networks: {
                    0: {
                        l2CostPerCharger: "Mix of $/kWh and $/session, by state.",
                        dcfcCostPerCharger: "",
                    },
                    1: {
                        l2CostPerCharger: "Varies by location",
                        dcfcCostPerCharger: "",
                    },
                    2: {
                        l2CostPerCharger: "$/minute, varies with power level and by state.",
                        dcfcCostPerCharger: "",
                    },
                    3: {
                        l2CostPerCharger: "Varies by location",
                        dcfcCostPerCharger: "",
                    },
                    4: {
                        l2CostPerCharger: "Flat fee - $1.50/hour",
                        dcfcCostPerCharger: "$/minute, by state",
                    },
                    5: {
                        l2CostPerCharger: "Flat fee - $1/hour",
                        dcfcCostPerCharger: "$10/hour",
                    },
                    6: {
                        l2CostPerCharger: "Varies by location",
                        dcfcCostPerCharger: "",
                    },
                    7: {
                        l2CostPerCharger: "Varies by location",
                        dcfcCostPerCharger: "",
                    },
                    8: {
                        l2CostPerCharger: "$1.25/hour - $3/hour",
                        dcfcCostPerCharger: "$5/hour - $6/hour",
                    },
                    9: {
                        l2CostPerCharger: "Varies by location",
                        dcfcCostPerCharger: "",
                    },
                    10: {
                        l2CostPerCharger: "Varies by location",
                        dcfcCostPerCharger: "",
                    },
                    11: {
                        l2CostPerCharger: "Free",
                        dcfcCostPerCharger: "",
                    },
                    12: {
                        l2CostPerCharger: "Flatt fee - $4.00/session",
                        dcfcCostPerCharger: "$7.50/session",
                    },
                },
                info: {
                    bev: "BEV empty to full: ~{hours} hours",
                    phev: "PHEV empty to full: ~{hours} hours",
                    0: {
                        left: {
                            title: "What are the different types of charging stations?",
                            alt: "none",
                            body: `
There are three levels of EV charging speed: **Level 1**, **Level 2** and **DC Fast Charge**. The time it takes to charge your EV depends on the size of your EV's battery, the rate of charge your vehicle can accept and the speed of the charging station.\
{break}
{break}
An hour connected to a **Level 1** charger (the standard US household outlet) will provide a battery electric vehicle (BEV) or plug-in hybrid electric vehicle (PHEV) with about 2-10 miles of additional range. This is best for charging overnight when your car isn't in use, or for PHEVs which have smaller batteries and can rely on their built-in gas engines if they run out of battery.\
{break}
{break}
**Level 2** is considerably faster than Level 1 and is currently the most common option for public charging. An hour of Level 2 charging will add about 20-25 miles to most BEVs and PHEVs.\
{break}
{break}
**DC Fast Charge** are the fastest type of charger publicly available today. BEV drivers can expect to gain over 150 miles of range in an hour on the charger. For most BEVs, drivers can expect to get almost 80% of their total range restored in just 30 minutes. It's important to note that PHEVs (and some older BEVs) are not compatible with DCFC stations.\
{break}
{break}
Tesla Supercharging stations are most similar to the speeds of DCFC, but only Tesla cars can charge at Tesla-branded public charging stations. See below for more on charging connector compatibility, and [Browse New EVs](/vehicles) to learn more about specific brands and models.`,
                        },
                        type: "Level 1",
                        center: "Best used when car is idle for many hours.",
                        right: "LEAST FAST",
                    },
                    1: {
                        left: {
                            title: "How do I select a public charging station that works with my EV?",
                            alt: "A graphic showing how different EV charger outlets appear",
                            body: `
Every EV on the road today in the U.S. is compatible with one of three standard charging connectors: SAE J1772, CHAdeMO, & Tesla. 
{break}
{break}
{image} 
{break}
{break}
{icon} SAE J1772 is designed for Level 1 and Level 2 charging, unless equipped with the CCS Combo plug, which enables DCFC. 
{break}
{break}
{icon} CHAdeMO connectors are capable of Level 1, Level 2 or DCFC with the same universal connector. 
{break}
{break}
{icon} Tesla can charge using the proprietary Tesla Supercharger network with their car's standard Tesla connector but will need to use an adapter to charge at SAE J1772 or CHAdeMO stations. 
{break}
{break}
Before you use a DC Fast Charger, learn about your EV's connector type. Some DC Fast Charger stations support both CHAdeMO and SAE J1772 connectors. The Tesla Supercharger network is not compatible with other vehicles.`,
                        },
                        type: "Level 2",
                        center: "Most readily available at public charging stations for adding range on the go or quickly topping off at home.",
                        right: "FASTER",
                    },
                    2: {
                        left: {
                            title: "Do I have to pay for a public charger? If so, how?",
                            alt: "none",
                            body: `
Some public charging stations are free, and some require the driver to pay. The station may allow a user to swipe their credit card right at the plug or offer subscriptions that can be prepaid monthly, often through an application on the driver's phone. Go to the links in the National Public Charging Networks table to learn more about your options.`,
                        },
                        type: "DC Fast Charger",
                        center: "Fastest charging available, with growing networks of public stations.",
                        right: "FASTEST",
                    },
                    3: {
                        left: {
                            title: "Public Charging Etiquette",
                            alt: "none",
                            body: `
Most public charger users follow simple “rules of the road” to ensure that everyone has a good experience. Some best practices are:
{break}{break}
{icon} When you're done charging, move your car out of the charging spot as soon as possible so others can use the charger.
{break}{break}
{icon} Don't unplug other's cars to plug yours in.
{break}{break}
{icon} Top off when you can, not when you have to. If you add a little charge here and there (especially when you wouldn't be in the car anyway - such as running into the grocery store or mall), you might be able to avoid long charging stops to juice up a battery that is nearly empty.`,
                        },
                        type: "none",
                        center: "Above figures are representative of a Battery Electric Vehicle (BEV) with a 62 kW battery capacity and a Plug-in Hybrid Vehicle (PHEV) with a 14kW battery capacity. Both vehicles have an EPA Rating of 31 kWh per 100 miles.",
                        right: "",
                    },
                },
            },
            compare: {
                header: "Compare Vehicles",
                subheader:
                    "Compare electric cars by EV range or price.  Click on the EV for more details, including total cost compared to a similar gas vehicle.",
                pickTwo: "Pick at least two vehicles to begin the comparison.",
                notAllAvailable: "Not all make / models are available.",
                selectVehicle: "Please select a vehicle",

                noIncentives: "No incentives available",
                milesPer30MinFastCharging: "miles per 30 min of fast charging",
                c02EmissionReduction: "c02 emission reduction",

                bar: {
                    selectMore: "Select more vehicles to continue",
                },
            },
            dealerMap: {
                heroTitle: "Find Dealers",
                heroContent:
                    "It's like your current car—minus the gas stations, oil changes, and tune-ups. Ready to go electric? Find a dealership near you and explore how driving an electric vehicle can help reduce emissions in our community.",
            },
            homeChargers: {
                hero: {
                    title: "Home Chargers",
                    text: "Going electric means no more gas stations. Home charging offers convenience, time savings and special energy rates for you to get an even cheaper charge.",
                },
                filters: {
                    make: "Vehicle Make",
                    model: "Vehicle Model",
                    price: "Product Price Range",
                    cordLength: "Cord Length",
                    wifi: "WIFI Connectivity",
                    gridshift: "Gridshift Qualified Chargers",
                    formFactor: "Type",
                    outlet: "Socket",
                },
                card: {
                    selectVehicle: "Please select car make and model to see charging time.",
                    button: "Details & Buy",
                },
            },
            incentives: {
                personalize: {
                    tooltip: {
                        household: {
                            income: "Incentive eligibility and the amount you receive often depends on on your income. Lower income customers are typically eligible for higher rebates.",
                            size: "Inclue all members of your household (spouse, dependents, etc). Few to no incentives mention households larger than 8 members, so select 8 if your family is any larger.",
                        },
                        tax: "Incentive eligibility and the amount you receive often depends on on your income. Lower income customers are typically eligible for higher rebates.",
                        zip: "Many incentives depend on where you live. Enter your zipcode to help determine what incentives are available to you.",
                    },
                },
            },
            nearby: {
                away: "away",
                hero: {
                    title: "Browse Nearby Vehicles",
                    description:
                        "Compare electric vehicles by range or price. Click on the EV for more details, including total cost compared to a similar gas vehicle.",
                },
                filters: {
                    header: "EVs Found: {countVehicles} vehicles at {countDealers} dealers",
                    banner: "Get $2,000 off your Electric Vehicle",
                    active: "Active Filters",
                },
            },
            vehicleList: {
                heroTitle: "{condition} Electric Vehicles",
                heroContent:
                    "Compare electric cars by EV range or price. Click on the EV for more details, including total cost compared to a similar gas vehicle.",
                noneFoundHeader: "No vehicles found",
                noneFoundMessage: "Adjust the filters to see vehicles",
                electricRange: "Electric Range",
                afterIncentives: "After Incentives",
                detailsButton: "Details",

                filters: "Filter Results",

                allElectricLabel: "All-Electric",
                plugInLabel: "Plug-in Hybrid",
                costAfterIncentives: "Cost After Incentives",
                minimumRangeLabel: "Electric Range",
                minimumSeatsLabel: "Minimum seats",
                availableNowLabel: "Available now",
                disclaimer:
                    "We do not endorse or recommend any specific vehicle or car manufacturer. Vehicles displayed may not reflect actual availability.",
                anySeats: "Any number of seats",
                anyRange: "Any range",
            },
            vehicleDetails: {
                back: "All vehicles",
                ariaPurchaseOptionsLabel: "Purchase options",
                disclaimer:
                    "* Denotes an estimate. You may need to contact the manufacturer to obtain a more accurate or discounted price. Vehicle specifications shown are standard, though variants may exist. Vehicles listed are for reference purposes only and availability is dependent on local inventory.\n\nSavings Calculator provides illustrative estimations throughout and does not guarantee the accuracy of any costs, savings, hardware specifications, or incentives estimates. Please refer to referenced sources and original equipment manufacturers for up to date costs, hardware specifications, and incentives information.",
                mobileDisclaimer: "* For more details including specs & calculations, please view this page on desktop",
                yourSelection: "Your Selection",
                similarGasVehicle: "Similar Gas Vehicle",
                personalizeIncentives: "Personalize Incentives",
                payment: {
                    seeAllCosts: "See All Costs",
                    seeAllIncentives: "See All Incentives",
                },
            },
        },
        maps: {
            header: "Locate EV Charging Stations",
            range: "Range Map",
            route: "Route Planner",
            dealer: "Dealer Map",
            availability: "Station Plugs & Availability",
            chargingStationNetworkUnavailable: "Link to Network Unavailable",
            dealerMap: {
                showButton: "Full list of dealers",
                hideButton: "Hide list of dealers",
                contact: "Contact",
                website: "Website",
            },
            stationsMap: {
                showButton: "Full list of stations",
                hideButton: "Hide list of stations",
                publicStationTitle: "Power Stations",
                publicStationDescription: "Level 2 Chargers",
                dcfcStationTitle: "High Power Stations",
                dcfcStationDescription: "DC fast chargers",
                privateStationTitle: "Other Stations",
            },
            availabilityMap: {
                searchChargingStations: "Search Charging Stations",
                availabilityStationTitle: "Availability & Plug Compatibility",
                availabilityStationDescription: "Has plug type & availability data.",
                plugOnlyStationTitle: "Plug Compatibility",
                plugOnlyStationDescription: "Has plug type data.",
                consent: "By using this service you consent to these",
                consentLink: "terms of use",
            },
            travelRadiusMap: {
                viewTravelRadius: "View Travel Radius",
                disclaimer:
                    "Note: Vehicle range radius is illustrative only, actual vehicle range varies by driving conditions and road availability. To estimate precise trip distances use “Map a Route.”",
                fullBatteryRange: "{currentElectricRange} mile electric range, full battery",
                fullGasRange: "{currentTotalRange} mile total range, full tank of gas",
            },
            dealersMap: {
                searchQualifiedDealers: "Search Qualified Dealers",
                brands: "Brands",
                allBrands: "All Brands",
                allDealers: "All Dealers",
                legend: "Dealership",
            },
            zipInput: {
                label: "Zip Code",
                placeholder: "Enter Zip Code",
                button: "Update Zip Code",
                error: "Invalid Zip Code, expecting 5 digits",
                invalid: "Invalid Zip Code",
            },
        },
        electricRange: {
            basedOnEpaEstimates: "Range based on EPA estimates.",
            longestElectricRange: "The {displayName} has the longest electric range",
            usingMaxChargingRates: "Using maximum L2 and DCFC charging rates supported by the vehicles.",
            mostMilesDcfc: "The {displayName} charges the most miles per 30 minutes of fast charging (DCFC)",
            mostMilesLevel2: "The {displayName} charges the most miles per hour with a Level 2 charger",
        },
        search: {
            title: "Search",
            searchInput: "Search",
            inputPlaceholder: "Search for vehicles, chargers, incentives, events, and dealers",
            filterHeader: "Filter results",
            zipcode: "Zip Code",
            vehicles: "Vehicles",
            chargingStations: "Chargers",
            incentives: "Incentives",
            events: "Events",
            showMore: "View more",
            showLess: "View less",
            noMatchingVehicles: "No matching vehicles found.",
            linkAllVehicles: "View all vehicles",
            noMatchingIncentives: "No matching incentives found.",
            linkAllIncentives: "View all incentives",
            noMatchingChargingStations: "No matching charging stations found.",
            linkAllChargingStations: "View all charging stations",
            noMatchingEvents: "No matching events found.",
            linkAllEvents: "View all events",
            noMatchingDealers: "No matching dealers found.",
            linkAllDealers: "View all dealers",
            dealerWebsite: "Website",
            chargingPrograms: "Charging Programs",
            noMatchingChargingPrograms: "No matching charging programs found.",
            linkAllChargingPrograms: "View all charging programs",
        },
        tco: {
            title: "Savings",
            description:
                "Over {years}, opting for an EV may save you up to an estimated {amount} due to its potentially lower ownership and maintenance expenses compared to a 100% gasoline-powered car.",
            descriptionNegative:
                "With the given inputs, over {years} this model of EV may increase your costs by an estimated {amount} compared to a 100% gasoline-powered car.",
            errorLoading: "An error occured while loading the page.",
            noElectricVehicleSelected: "Please select at least one electric vehicle.",
            filters: {
                householdIncome: "Household income",
                householdIncomeTip:
                    "Combine income from all household members. Used to determine incentive eligibility.",
                householdSize: "Size of household",
                taxFilingStatus: "Tax filing status",
                taxFilingSingle: "Single",
                taxFilingMarried: "Married",
                taxFilingHeadOfHousehold: "Head of Household",
                tradeInVehicle: "Trade-in a vehicle",
                ownershipLabel: "Purchase Method",
                milesDrivenAnnuallyLabel: "Miles Driven Per Year",
                milesDrivenAnnuallyTip: "Estimate the number of miles you drive each year.",
                evMilesDrivenAnnuallyLabel: "Electric Miles Driven",
                percentElectric: "Electric Miles Driven",
                percentElectricTip:
                    "Estimate what percentage of your driving miles will be completely electric.\n\n  If you recharge daily and drive less per day than the all-electric range, your percentage will be near 100%.\n\n  For most typical driving beyond all-electric range, 50% is a safe assumption.",
                yearsOwnedLabel: "Years of Ownership",
                interestRateLabel: "Interest Rate",
                salesTaxLabel: "Sales Tax",
                downPaymentLabel: "Down Payment",
                leaseResalePercentageLabel: "Residual Value",
                gasPriceLabel: "Price of Gasoline",
                gasPriceTip:
                    "Enter your local gas price. Our default is based on your region’s most recent gas price, updated monthly.",
                electricityRateLabel: "Electricity Rate",
                electricRateDisclaimer:
                    "Electricity rate is calculated as the average for four months of summer off-peak and eight months of winter off-peak. To learn more, please click [here](https://svcleanenergy.org/residential-rates/)).",
                purchase: "Purchase",
                publicChargingPortionLabel: "Public Charging Percentage",
                publicChargingCostLabel: "Est. Public Electricity Rate",
                publicChargingOptionFree: "Free",
                publicChargingOptionHigh: "High: $0.60 / kWh",
                publicChargingOptionLow: "Low: $0.20 / kWh",
                publicChargingOptionMedium: "Medium: $0.40 / kWh",
                nonEndorseDisclaimer:
                    "We do not endorse or recommend any specific vehicle or car manufacturer. Vehicles displayed may not reflect actual availability.",
            },
            fuelCosts: {
                monthlyCostSavings: "The {name} is {amount} cheaper to fill up monthly",
                monthlyCostSavingsNegative: "The {name} is {amount} more expensive to fill up monthly",
            },
            costByCategory: {
                vehicle: "Vehicle",
                vehicleNetIncentives: "Vehicle net incentives",
                electricity: "Electricity",
                gasoline: "Gasoline",
                maintenance: "Maintenance",
                insurance: "Insurance",
                cheaperToOwn:
                    "The {name} is {amount} cheaper to own over {years, plural, one {1 year} other {{years} years}}.",
                cheaperToOwnNegative:
                    "The {name} may increase costs by {amount} over {years, plural, one {1 year} other {{years} years}}.",
            },
            costByYear: {
                breakevenYearSame:
                    "The cost to own {name} stays relatively the same over {years, plural, one {1 year} other {{years} years}}.",
                breakevenYear:
                    "The {name} becomes cheaper to own after {years, plural, one {1 year} other {{years} years}}.",
                breakevenYearInverted:
                    "The {name} itself becomes more expensive to own over {years, plural, one {1 year} other {{years} years}}.",
            },
        },
        incentives: {
            incentives: "Incentives",
            heroTitle: "Electric Vehicle and Charger Incentives",
            heroContent:
                "You may be eligible for a range of incentives, including EV rebates, EV tax credits, and other benefits. Adjust the filters to personalize your results.",
            incentiveDescription: "Read Full Description",
            flipBack: "Flip Back",
            noneFoundHeader: "No incentives found",
            noneFoundMessage: "Adjust the filters to see eligible incentives",
            selectAVehicle: "Select a Vehicle",
            selectAVehicleMessage: "Please select a vehicle to view incentive options.",
            filters: {
                householdIncome: "Household income",
                householdIncomeTip:
                    "Combine income from all household members. Used to determine incentive eligibility.",
                householdSize: "Size of household",
                taxFilingStatus: "Tax filing status",
                taxFilingSingle: "Single",
                taxFilingMarried: "Married",
                taxFilingHeadOfHousehold: "Head of Household",
                tradeInVehicle: "Retiring A Vehicle",
                ownershipLabel: "Purchase Method",
                lease: "Lease",
                purchase: "Purchase",
                vehicleSelector: "Electric Vehicle Considered",
                eligibilityFilters: "Eligibility Filters",
                sortBy: "Sort by",
                sortHighToLow: "Largest first",
                sortLowToHigh: "Smallest first",
                sortAToZ: "A to Z",
                sortZToA: "Z to A",
                update: "Update Incentive Eligibility",
            },
        },
    },
};

export default ENGLISH;
