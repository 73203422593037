const PROD_URL = "https://app.production.zappyride.com";
const PROD_API_TOKEN =
    "eyJvcmciOiI2NDM4MGYxNGFkZGJiYTAwMDE4MWYwMTEiLCJpZCI6IjY4YjgwNWMzMWY1YTQ0ZWM4MmNlZDIwOTk3NWQ5NzE4IiwiaCI6Im11cm11cjEyOCJ9";

const DEV_URL = "https://app.beta.zappyride.com"; // add to .env for local dev
const DEV_API_TOKEN =
    "eyJvcmciOiI2NjRmNjcwY2FiY2FmYzAwMDE0MDAyZjkiLCJpZCI6ImQ3ZTk0NGFiYjQxNjQ2OTE5YTk5MWE5YzRlNWIxYzlkIiwiaCI6Im11cm11cjEyOCJ9"; // add to .env for local dev

const API_URL = (() => {
    if (import.meta.env.MODE === "production") {
        if (window.location.hostname === "svce.ev.d.zappyride.com") {
            return import.meta.env.VITE_DEV_BASE_APP_URL || DEV_URL;
        } else {
            return import.meta.env.VITE_PROD_BASE_APP_URL || PROD_URL;
        }
    } else if (import.meta.env.MODE === "staging") {
        return import.meta.env.VITE_DEV_BASE_APP_URL || DEV_URL;
    } else {
        return import.meta.env.VITE_DEV_BASE_APP_URL || DEV_URL;
    }
})();

const API_TOKEN = (() => {
    if (import.meta.env.MODE === "production") {
        if (window.location.hostname === "svce.ev.d.zappyride.com") {
            return import.meta.env.VITE_DEV_APP_KEY || DEV_API_TOKEN;
        } else {
            return import.meta.env.VITE_PROD_APP_KEY || PROD_API_TOKEN;
        }
    } else if (import.meta.env.MODE === "staging") {
        return import.meta.env.VITE_DEV_APP_KEY || DEV_API_TOKEN;
    } else {
        return import.meta.env.VITE_DEV_APP_KEY || DEV_API_TOKEN;
    }
})();

export const apiSettings = {
    url: API_URL,
    token: API_TOKEN,
};
