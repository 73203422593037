import {
    calcUsedVehicleBatteryCapacity,
    calcUsedVehicleElectricRange,
    calcUsedVehicleMsrp,
    calcUsedVehicleTotalRange,
} from "../../calculations/usedVehicles/usedVehicles";
import type { ApiElectricVehicle, ElectricVehicle } from "../../types";
import { transformVehicleIncentive } from "./transformVehicleIncentive";
import { transformApiToLanguage, type ApiLanguage } from "@src/constants/apiLanguages";

export const transformElectricVehicle = (apiEv: ApiElectricVehicle, apiLanguage: ApiLanguage): ElectricVehicle => {
    // The field `available_for_purchase_as_new` is
    // only false if the vehicle is in used status.
    // New and upcoming vehicles both have it set
    // to true.
    const isUsed = !apiEv.available_for_purchase_as_new;

    return {
        id: `${apiEv.electric_vehicle_id}`,
        language: transformApiToLanguage(apiLanguage),
        handle: apiEv.handle,
        modelId: `${apiEv.make}-${apiEv.model}-${apiEv.trim}`,
        msrp: isUsed ? calcUsedVehicleMsrp(apiEv) : apiEv.msrp,
        customPrice: apiEv.custom_price,
        modelYear: apiEv.model_year,
        make: apiEv.make,
        model: apiEv.model,
        subtype: apiEv.form_factor,
        type: apiEv.type,
        trim: apiEv.trim,
        fuelType: apiEv.fuel,
        displayName: apiEv.display_name,
        batteryCapacity: isUsed ? calcUsedVehicleBatteryCapacity(apiEv) : apiEv.battery_capacity,
        acChargingPower: apiEv.ac_charging_power,
        dcChargingPower: apiEv.dc_charging_power,
        doors: apiEv.doors,
        seatsMin: apiEv.seats_min,
        seatsMax: apiEv.seats_max,
        cargoVolumeMin: apiEv.cargo_volume_min,
        cargoVolumeMax: apiEv.cargo_volume_max,
        acceleration: apiEv.acceleration,
        officialWebsiteUrl: apiEv.official_website_url,
        electricRange: isUsed ? calcUsedVehicleElectricRange(apiEv) : apiEv.electric_range,
        totalRange: isUsed ? calcUsedVehicleTotalRange(apiEv) : apiEv.total_range,
        electricEfficiency: apiEv.electric_efficiency,
        fossilFuelEfficiency: apiEv.fossil_fuel_efficiency,
        drivetrain: apiEv.drivetrain,
        curbWeight: apiEv.curb_weight,
        images: apiEv.images.map((image) => ({
            altText: image.alt,
            urlFull: image.url_full,
            urlThumbnail: image.url_thumbnail || "",
        })),
        incentives: apiEv.incentives.map((incentive) => transformVehicleIncentive(incentive, apiLanguage)),
        equivalentGasVehicle: !apiEv.equivalent_gas_vehicle
            ? null
            : {
                  epaId: `${apiEv.equivalent_gas_vehicle.epa_id}`,
                  id: `${apiEv.equivalent_gas_vehicle.epa_id}`,
                  displayName: apiEv.equivalent_gas_vehicle.model,
                  msrp: apiEv.equivalent_gas_vehicle.msrp,
                  modelYear: apiEv.equivalent_gas_vehicle.model_year,
                  make: apiEv.equivalent_gas_vehicle.make,
                  model: apiEv.equivalent_gas_vehicle.model,
                  type: apiEv.type,
                  subtype: apiEv.form_factor,
                  fossilFuelEfficiency: apiEv.equivalent_gas_vehicle.fossil_fuel_efficiency,
                  fuelType: "GAS",
              },
        status: isUsed ? "used" : apiEv.availability === "released" ? "new" : "upcoming",
        gvwr: apiEv.gvwr,
        horsepower: apiEv.horsepower,
        towingCapacity: apiEv.towing_capacity,
        plugTypes: apiEv.plug_types,
    };
};
