import { Box, Fade, Stack } from "@mui/material";
import Button from "@src/components/button";
import ButtonLink from "@src/components/buttonLink";
import { useApplicationRoutes } from "@src/constants/routes";
import { useVehicleSearch } from "@src/screens/search/hooks";
import { useIntl } from "@src/utils/hooks/useIntl";
import { useSearchParams } from "react-router-dom";
import VehicleTile from "../list/components/vehicleTile";

export default function VehicleComparisonBar() {
    const { formatMessageString } = useIntl();
    const [search, setSearchParams] = useSearchParams();
    const routes = useApplicationRoutes();

    const handle = search.get("handle");
    const handle2 = search.get("handle2");
    const handle3 = search.get("handle3");

    const { vehicles: vehicle1 } = useVehicleSearch(handle || "vehicle-not-found");
    const { vehicles: vehicle2 } = useVehicleSearch(handle2 || "vehicle-not-found");
    const { vehicles: vehicle3 } = useVehicleSearch(handle3 || "vehicle-not-found");

    const vehicles = [vehicle1[0], vehicle2[0], vehicle3[0]].filter(Boolean);

    const handleClear = () => {
        search.delete("handle");
        search.delete("handle2");
        search.delete("handle3");
        setSearchParams(search, { replace: true });
    };

    if (!vehicles.length) {
        return null;
    }

    return (
        <Fade in={!!vehicles.length}>
            <Box
                className="vehicle-comparison-bar"
                sx={{
                    position: "fixed",
                    overflow: "hidden",
                    zIndex: 999,
                    bottom: 0,
                    left: 0,
                    minWidth: "100%",
                    py: 3,
                    backgroundColor: (t) => t.palette.grey["100"],
                }}
            >
                <Stack direction={"row"} alignItems="center" justifyContent={"center"} gap={2}>
                    <Box px={2}>
                        <Button
                            size={"small"}
                            variant="contained"
                            sx={{
                                borderRadius: 2,
                                px: 1,
                                backgroundColor: "grey.800",
                                color: "white.main",
                                "&:hover": {
                                    backgroundColor: "primary.main",
                                },
                            }}
                            onClick={handleClear}
                        >
                            {formatMessageString({ id: "common.clear" })}
                        </Button>
                    </Box>
                    {vehicle1[0] && (
                        <VehicleTile isComparisonBar={true} currentComparisonHandle={"handle"} vehicle={vehicle1[0]} />
                    )}
                    {vehicle2[0] && (
                        <VehicleTile isComparisonBar={true} currentComparisonHandle={"handle2"} vehicle={vehicle2[0]} />
                    )}
                    {vehicle3[0] && (
                        <VehicleTile isComparisonBar={true} currentComparisonHandle={"handle3"} vehicle={vehicle3[0]} />
                    )}
                    <Box px={2}>
                        <ButtonLink
                            variant="contained"
                            sx={{ borderRadius: 2 }}
                            to={`${routes.CompareVehicles}?${search}`}
                            disabled={vehicles.length < 2}
                        >
                            {vehicles.length < 2
                                ? formatMessageString({ id: "screens.compare.bar.selectMore" })
                                : formatMessageString({ id: "common.compare" })}
                        </ButtonLink>
                    </Box>
                </Stack>
            </Box>
        </Fade>
    );
}
