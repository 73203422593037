import { useApplicationRoutes } from "@src/constants/routes";
import { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";

export const useUserGeolocation = () => {
    const routes = useApplicationRoutes();
    const isBrowseNearby = useMatch(routes.NearbyVehicles);
    const [userLocation, setUserLocation] = useState<GeolocationCoordinates | undefined>();

    useEffect(() => {
        if (isBrowseNearby && "navigator" in window) {
            window.navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation(position.coords);
                },
                console.error,
                {
                    enableHighAccuracy: true,
                    timeout: 60 * 1000,
                },
            );
        }
    }, [isBrowseNearby]);

    return {
        userLocation,
    };
};
