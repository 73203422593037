import { Vehicle } from "@src/types";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export const useVehicleComparisonHandle = (
    vehicle: Vehicle & { handle: string },
    currentComparisonHandle_?: string,
) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedCompareHandles = useMemo(
        () => [searchParams.get("handle"), searchParams.get("handle2"), searchParams.get("handle3")].filter(Boolean),
        [searchParams],
    ) as string[];

    const availableComparisonHandle = useMemo(() => {
        if (searchParams.get("handle")) {
            if (searchParams.get("handle2")) {
                if (searchParams.get("handle3")) {
                    return null;
                } else {
                    return "handle3";
                }
            } else {
                return "handle2";
            }
        } else {
            return "handle";
        }
    }, [searchParams]);

    const currentComparisonHandle = useMemo(() => {
        if (currentComparisonHandle_) return currentComparisonHandle_;

        if (selectedCompareHandles.includes(vehicle.handle)) {
            if (searchParams.get("handle") === vehicle.handle) {
                return "handle";
            }
            if (searchParams.get("handle2") === vehicle.handle) {
                return "handle2";
            }
            if (searchParams.get("handle3") === vehicle.handle) {
                return "handle3";
            }
        }
    }, [searchParams, selectedCompareHandles, vehicle.handle, currentComparisonHandle_]);

    const handleRemoveFromCompare = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();

            if (currentComparisonHandle) {
                searchParams.delete(currentComparisonHandle);
                setSearchParams(searchParams);
            }
        },
        [currentComparisonHandle, searchParams, setSearchParams],
    );

    const handleAddToCompare = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();

            if (availableComparisonHandle) {
                searchParams.set(availableComparisonHandle, vehicle.handle);
                setSearchParams(searchParams);
            }
        },
        [availableComparisonHandle, searchParams, vehicle.handle, setSearchParams],
    );

    return {
        add: handleAddToCompare,
        remove: handleRemoveFromCompare,
        currentComparisonHandle,
        availableComparisonHandle,
    };
};
