import { DirectionsCar } from "@mui/icons-material";
import BottomToolbarTitle from "../BottomToolbarTitle";
import Chevron from "../Chevron";

export const BOTTOM_TOOLBAR_LINKS = (routes: Record<string, string | ((param: string) => string)>) => [
    {
        title: (
            <BottomToolbarTitle
                id="header.bottom.0.main"
                iconSide="left"
                icon={<DirectionsCar style={{ verticalAlign: "middle", fontSize: "2.5em" }} />}
            />
        ),
        color: "common.main",
        items: [
            {
                title: "header.bottom.0.main",
                href: routes.Root as string,
            },
        ],
    },
    {
        title: <BottomToolbarTitle id="header.bottom.1.main" iconSide="right" icon={<Chevron />} />,
        color: "common.main",
        items: [
            {
                title: "header.bottom.1.new",
                href: routes.Vehicles as string,
            },
            {
                title: "header.bottom.1.used",
                href: routes.UsedVehicles as string,
            },
            {
                title: "header.bottom.1.nearby",
                href: routes.NearbyVehicles as string,
            },
            {
                title: "header.bottom.1.compare",
                href: routes.CompareVehicles as string,
            },
            {
                title: "header.bottom.1.dealers",
                href: routes.Dealers as string,
            },
        ],
    },
    {
        title: <BottomToolbarTitle id="header.bottom.2.main" iconSide="right" icon={<Chevron />} />,
        color: "common.white",
        items: [
            {
                title: "header.bottom.2.charging",
                href: routes.ChargingStations as string,
            },
            {
                title: "header.bottom.2.chargers",
                href: routes.HomeChargers as string,
            },
            {
                title: "header.bottom.2.gridshift",
                href: "https://www.svcleanenergy.org/gridshift-ev/",
            },
        ],
    },
    {
        title: "header.bottom.3.main",
        color: "white",
        items: [
            {
                title: "",
                href: routes.Incentives as string,
            },
        ],
    },
    // {
    //   title: 'Other Sites',
    //   color: 'common.white',
    //   items: [
    //     {
    //       title: 'Appliances Assistant',
    //       href: 'http://appliances.svcleanenergy.org/',
    //     },
    //     {
    //       title: 'Solar + Battery Assistant',
    //       href: 'https://solarbattery.svcleanenergy.org/',
    //     },
    //     {
    //       title: 'Battery Assistant',
    //       href: 'https://battery.svcleanenergy.org/',
    //     },
    //     {
    //       title: 'Silicon Valley Clean Energy',
    //       href: `${SVCE_WEBSITE_BASE}/ehub`,
    //     },
    //   ],
    // },
];
