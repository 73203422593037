import { DEFAULT_GOOGLE_MAP_CENTER } from "@src/components/maps/components/baseGoogleMap/constants";
import { EChargerLevel, ETaxFilingStatus, EVehicleDrivetrain } from "@src/types";
import { SALES_TAX } from "@src/utils/assumptions";
import type { UserPrefs } from "../types/userPrefs";

export const USER_PREF_KEY = "userPrefsSVCE";

export const USER_PREF_PRESETS: UserPrefs = {
    chargerSortType: "price",
    chargerSortDirection: "asc",
    chargerHomeOutletFilters: {
        "6-20": false,
        "6-30": false,
        "10-30": false,
        "14-30": false,
        "6-50": false,
        "14-50": false,
        "14-60": false,
        Hardwired: false,
    },
    chargerFormFactorFilters: {
        mounted: false,
        portable: false,
    },
    chargerWifiFilter: {
        yes: false,
        no: false,
    },
    chargerGridShiftFilter: {
        yes: false,
        no: false,
    },
    chargerBudgetDollars: 2_000,
    chargerCordLengthFeet: 25,
    downPayment: 0.01,
    electricityRate: 0,
    evMilesDrivenAnnually: 1,
    gasPrice: 4.5,
    householdIncome: 75000,
    householdSize: 1,
    interestRate: 0.06,
    milesDrivenAnnually: 20000,
    model: "",
    salesTax: SALES_TAX.value,
    taxFilingStatus: ETaxFilingStatus.SINGLE,
    turnInClunker: false,
    yearsOwned: 5,
    zipcode: "94087",
    zipcodeError: false,
    zipcodeIsDefault: true,
    zipcodeLastValid: "94087",
    chargerLevel: EChargerLevel.LEVEL_2,
    electricMilesPortionForPhev: 50,
    publicChargingCostInCentsPerKwh: 20,
    publicChargingPercentage: 0,
    showHomeElectricityCost: true,
    showGasolineCost: true,
    homeElectricBillMonthlyAvg: 200,
    leaseResalePercentage: 0.45,
    ownership: "lease",
    fuelTypes: ["BEV"],
    priceMax: 40_000,
    electricRange: 200,
    seatsMin: 5,
    vehicleSubtypes: [],
    vehicleMake: "All",
    vehicleHandle: "All",
    vehicleSortBy: "priceAsc",
    vehicleModelYear: new Date().getFullYear(),
    vehicleDrivetrain: EVehicleDrivetrain.ALL,
    vehicleStatusCondition: "new",
    nearbyRadiusMiles: 50,
    geolocation: {
        latitude: DEFAULT_GOOGLE_MAP_CENTER.lat,
        longitude: DEFAULT_GOOGLE_MAP_CENTER.lng,
    } as GeolocationCoordinates,
};
