import type { ApiIncentive } from "./incentive";

export enum EChargerLevel {
    NONE = 99,
    LEVEL_1 = 1,
    LEVEL_2 = 2,
    DCFC = 3,
}

export type TChargerFormFactor = "mounted" | "portable";

export type TChargerHomeOutlet = "6-20" | "6-30" | "10-30" | "14-30" | "6-50" | "14-50" | "14-60" | "Hardwired";

export interface Charger {
    charger_id: string;
    make: "Enphase";
    model: string;
    model_variant: string;
    price: number;
    price_src: string;
    amps: number;
    form_factor: "Wall mounted" | "Portable";
    cord_length: number;
    home_outlet: TChargerHomeOutlet;
    plug_types: "J1772";
    wifi: false;
    warranty: number;
    safe_outdoors: boolean;
    made_in_america: boolean;
    details_link: string;
    spec_src: string;
    img: string;
    has_discount: false;
    discount_value: number;
    discount_code: string;
    certifications: string;
    volts_ac: number;
    kw_ac: number;
    gridshift?: boolean;
    incentives: ApiIncentive[];
}
