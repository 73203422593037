import { USER_PREF_PRESETS } from "@src/constants/userPrefs";
import { useLanguage } from "@src/context/intl/useLanguage";
import { useUserPrefs } from "@src/context/userPrefs/useUserPrefs";
import { useVehicleCache } from "@src/context/vehicles/useVehicleCache";
import { getValidZipcode } from "@src/utils/zipcodes";
import { useCallback, useEffect, useMemo } from "react";
import { type ApiElectricVehicle } from "../../types";
import { fetchRecords } from "../../utils/fetch";
import { transformElectricVehicle } from "../transformers/transformElectricVehicle";
import {
    getIndexDBValue,
    getPastMonthObjectStoreKeys,
    getTodayObjectStoreKey,
    removeIndexDBValue,
    setIndexDBValue,
} from "../../utils/indexDBCaching";

const path = "/vehicles";
const defaultParams = {
    zipcode: USER_PREF_PRESETS.zipcode,
    taxFilingStatus: USER_PREF_PRESETS.taxFilingStatus,
    householdSize: USER_PREF_PRESETS.householdSize,
    householdIncome: USER_PREF_PRESETS.householdIncome,
    turnInClunker: true, // opposite of USER_PREF_PRESETS.turnInClunker
};

const INDEX_DB_STORAGE_KEY_PREFIX = "svce_electric_vehicles";
const INDEX_DB_STORAGE_KEY = getTodayObjectStoreKey(INDEX_DB_STORAGE_KEY_PREFIX);

// Keep the users indexdb relatively clean by deleting keys for past two weeks, if they exist
getPastMonthObjectStoreKeys(INDEX_DB_STORAGE_KEY_PREFIX).forEach(async (objectStoreKey) => {
    await removeIndexDBValue(objectStoreKey);
});

export default function useElectricVehicles(skipFetch?: boolean) {
    const { zrLanguage } = useLanguage();
    const { userPrefs, setUserPrefs } = useUserPrefs();
    const { electricVehicles, fetchVehicles, status, error } = useVehicleCache();

    const zipcode = getValidZipcode(userPrefs);

    const fetch = useCallback(
        async (params: Record<string, string | null>) => {
            const refreshData = async () => {
                const data = await fetchElectricVehicles(params);
                const toStore = data.vehicles.map((vehicle) => transformElectricVehicle(vehicle, zrLanguage));
                setIndexDBValue(INDEX_DB_STORAGE_KEY, toStore);
                return toStore;
            };

            const internalFetchVehicles = async () => {
                try {
                    const stored = await getIndexDBValue(INDEX_DB_STORAGE_KEY);
                    if (stored) {
                        try {
                            return stored;
                        } catch (e) {
                            console.error(e);
                        } finally {
                            refreshData();
                        }
                    } else {
                        return await refreshData();
                    }
                } catch (e) {
                    if (e instanceof Error && e.message.includes("postcode")) {
                        setUserPrefs({ zipcodeError: true });
                    }
                    console.error(e);
                    return [];
                }
            };

            fetchVehicles(internalFetchVehicles, "electric", JSON.stringify(params));
        },
        [fetchVehicles, setUserPrefs, zrLanguage],
    );

    useEffect(() => {
        if (skipFetch) return;

        fetch({
            include_used_vehicles: "true",
            postcode: zipcode,
            tax_filing_type: userPrefs?.taxFilingStatus || defaultParams.taxFilingStatus,
            household_size: (userPrefs?.householdSize || defaultParams.householdSize).toString(),
            turn_in_clunker: defaultParams.turnInClunker.toString(),
            household_income: (userPrefs?.householdIncome === 0
                ? 1
                : userPrefs?.householdIncome || defaultParams.householdIncome
            ).toString(),
            lang: zrLanguage,
        });
    }, [
        skipFetch,
        fetch,
        userPrefs?.householdIncome,
        userPrefs?.householdSize,
        userPrefs?.taxFilingStatus,
        zipcode,
        zrLanguage,
    ]);

    const allElectricVehicles = useMemo(() => electricVehicles || [], [electricVehicles]);

    const usedElectricVehicles = useMemo(
        () => allElectricVehicles.filter((vehicle) => vehicle.status === "used"),
        [allElectricVehicles],
    );
    const newElectricVehicles = useMemo(
        () => allElectricVehicles.filter((vehicle) => vehicle.status === "new"),
        [allElectricVehicles],
    );
    const upcomingElectricVehicles = useMemo(
        () => allElectricVehicles.filter((vehicle) => vehicle.status === "upcoming"),
        [allElectricVehicles],
    );

    const newPlusUsedElectricVehicles = useMemo(
        () => newElectricVehicles.concat(usedElectricVehicles),
        [newElectricVehicles, usedElectricVehicles],
    );

    return {
        vehicles: newElectricVehicles,
        allElectricVehicles,
        newElectricVehicles,
        usedElectricVehicles,
        upcomingElectricVehicles,
        newPlusUsedElectricVehicles,
        // allElectricVehicles: [
        //   ...(newElectricVehicles || []),
        //   ...(usedElectricVehicles || []),
        //   ...(upcomingElectricVehicles || []),
        // ],
        error,
        status,
    };
}

const fetchElectricVehicles = (params?: Record<string, string | null>): Promise<{ vehicles: ApiElectricVehicle[] }> => {
    return fetchRecords(path, params);
};
