import type { PaletteOptions } from "@mui/material/styles";
// eslint-disable-next-line no-duplicate-imports
import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { pSBC } from "./psbc";

const maxContentWidth = "74rem";

const colors = {
    white: "#ffffff",
    black: "#000000",
    red: "#E81123",
    grey: "#797D83",
    offWhite: "#F5F5F5",
    blue: "#003863",
    orange: "#E56D0A", // contrast 3.22 : 1
    // orange: '#D14900', // contrast 4.5 : 1
    yellow: "#F9C606",
    green: "#02B198", // contrast - 2.71 : 1
    // green: '#208378', // contrast - 4.59 : 1
    purple: "#705090",
} as const;

declare module "@mui/material/styles" {
    interface Theme {
        values: {
            maxContentWidth: string;
            sectionPadding: string;
            sectionPaddingSm: string;
        };
    }

    interface ThemeOptions {
        values: {
            maxContentWidth: string;
            sectionPadding: string;
            sectionPaddingSm: string;
        };
    }

    interface PaletteColor {
        100?: PaletteColor["main"];
        200?: PaletteColor["main"];
        300?: PaletteColor["main"];
        400?: PaletteColor["main"];
        500?: PaletteColor["main"];
        600?: PaletteColor["main"];
        700?: PaletteColor["main"];
        800?: PaletteColor["main"];
        900?: PaletteColor["main"];
    }

    interface Palette {
        white: Palette["primary"];
        black: Palette["primary"];
        red: Palette["primary"];
        blue: Palette["primary"];
        yellow: Palette["primary"];
        purple: Palette["primary"];
        green: Palette["primary"];
        orange: Palette["primary"];
    }

    // eslint-disable-next-line no-shadow
    interface PaletteOptions {
        white?: PaletteOptions["primary"];
        black?: PaletteOptions["primary"];
        blue?: PaletteOptions["primary"];
        yellow?: PaletteOptions["primary"];
        purple?: PaletteOptions["primary"];
        green?: PaletteOptions["primary"];
        orange?: PaletteOptions["primary"];
        red?: PaletteOptions["primary"];
    }
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        label: true;
        label2: true;
        link: true;
    }
}

declare module "@mui/material/Table" {
    interface TablePropsVariantOverrides {
        simple: true;
    }
}

const toDarkColor = (hex: string, darkenBy = 0.2) => {
    return pSBC(-darkenBy, hex, false, false);
};

const toLightColor = (hex: string, lightenBy = 0.2) => {
    return pSBC(lightenBy, hex, false, false);
};

const palette = {
    contrastThreshold: 4.5,
    primary: {
        main: colors.green,
        light: toLightColor(colors.green),
        dark: "rgb(18, 91, 73)",
    },
    secondary: {
        main: colors.grey,
        light: toLightColor(colors.grey),
        dark: toDarkColor(colors.grey),
    },
    white: {
        main: colors.white,
    },
    black: {
        main: colors.black,
    },
    blue: {
        main: colors.blue,
        light: "#A3D9D6",
        dark: "#003863",
    },
    grey: {
        100: "#E4E4E4",
        200: "#F9FAFB",
        300: toLightColor(colors.grey, 0.4),
        400: "#A3A7A9",
        500: colors.grey,
        600: toDarkColor(colors.grey, 0.2),
        700: toDarkColor(colors.grey, 0.4),
        900: toDarkColor(colors.grey, 0.6),
    },
    yellow: {
        main: colors.yellow,
        light: toLightColor(colors.yellow),
        dark: toDarkColor(colors.yellow),
    },
    purple: {
        main: colors.purple,
        light: toLightColor(colors.purple),
        dark: toDarkColor(colors.purple),
    },
    green: {
        main: colors.green,
        light: toLightColor(colors.green),
        dark: toDarkColor(colors.green),
    },
    orange: {
        main: colors.orange,
        light: toLightColor(colors.orange),
        dark: toDarkColor(colors.orange),
    },
    red: {
        main: colors.red,
        light: toLightColor(colors.red),
        dark: toDarkColor(colors.red),
    },
    text: {
        primary: colors.black,
        secondary: toLightColor(colors.black),
        disabled: toLightColor(toLightColor(colors.black)),
    },
    background: {
        // default: colors.offWhite,
        default: colors.white,
    },
} satisfies PaletteOptions;

const theme = createTheme({
    palette,
    typography: {
        fontFamily: "proxima-nova, sans-serif",
        fontSize: 16,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
    },
    values: {
        maxContentWidth: maxContentWidth,
        sectionPadding: "5rem 0",
        sectionPaddingSm: "5rem 1rem",
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    border: "none",
                    position: "static",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: colors.offWhite,
                    color: palette.grey["900"],
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: "1rem",
                    fontWeight: 400,
                    lineHeight: 1.25,
                },
                h1: {
                    fontSize: "48px",
                    fontWeight: 600,
                    lineHeight: "52px",
                },
                h2: {
                    fontSize: "2rem",
                    fontWeight: 700,
                },
                h3: {
                    fontSize: "1.5625rem",
                    fontWeight: 400,
                },
                body1: {
                    "b, strong": {
                        fontWeight: 700,
                    },
                },
                body2: {
                    color: palette.primary.main,
                },
            },
            variants: [
                {
                    props: { variant: "link" },
                    style: {
                        color: palette.blue.main,
                    },
                },
                {
                    props: { variant: "label" },
                    style: {
                        textTransform: "uppercase",
                        color: palette.grey["900"],
                        fontSize: "0.875rem",
                    },
                },
                {
                    props: { variant: "label2" },
                    style: {
                        textTransform: "uppercase",
                        color: palette.grey["900"],
                        fontSize: "0.75rem",
                    },
                },
            ],
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    border: "none",
                    borderRadius: "unset",
                    boxShadow: "unset",
                    "&:before": {
                        display: "none",
                    },
                    variants: [
                        {
                            props: { variant: "outlined" },
                            style: {
                                borderBottom: "1px solid",
                                borderColor: palette.grey["300"],

                                ".MuiAccordionSummary-content": {
                                    fontSize: "1.5rem",
                                    fontWeight: 400,
                                    margin: "1rem 0",
                                },

                                ".MuiAccordionSummary-expandIconWrapper": {
                                    color: palette.green.main,
                                    transform: "scaleY(1)",
                                    "&.Mui-expanded": {
                                        transform: "scaleY(-1)",
                                    },
                                    ".MuiSvgIcon-root": {
                                        height: "3rem",
                                        width: "3rem",
                                    },
                                },
                            },
                        },
                    ],
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    fontSize: "1.125rem",
                    fontWeight: 600,
                    lineHeight: 1.17,
                },
            },
        },
        MuiButton: {
            defaultProps: {
                // disableRipple: true,
                // disableTouchRipple: true,
                size: "medium",
            },
            styleOverrides: {
                root: {
                    color: palette.white.main,
                    fontSize: "1rem",
                    fontWeight: 700,
                    textTransform: "none",
                    textAlign: "center",
                    lineHeight: 1.25,
                    minHeight: "2.75rem",
                    paddingLeft: "2.5rem",
                    paddingRight: "2.5rem",
                    borderRadius: "unset",
                    boxShadow: "unset",
                    "&:hover": {
                        boxShadow: "unset",
                    },
                },
                outlined: {
                    backgroundColor: "transparent",
                    borderColor: palette.grey["600"],
                    borderWidth: "1px",
                    borderStyle: "solid",
                    color: palette.grey["600"],
                    "&:hover": {
                        borderColor: palette.grey["600"],
                        backgroundColor: palette.green.main,
                        color: palette.white.main,
                    },
                },
                text: {
                    color: palette.grey["900"],
                    backgroundColor: "transparent",
                    "&:hover": {
                        backgroundColor: colors.offWhite,
                    },
                },
                sizeSmall: {
                    fontSize: "0.875rem",
                    minHeight: "2.25rem",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                },
                sizeMedium: {
                    minHeight: "2.75rem",
                    paddingLeft: "2.5rem",
                    paddingRight: "2.5rem",
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontSize: "inherit",
                    color: palette.blue.main,
                    textDecoration: "underline",
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: "unset",
                    padding: "1rem",
                    variants: [
                        {
                            props: { variant: "elevation" },
                            style: {
                                boxShadow: "0px 25px 50px 0px #00000026",
                            },
                        },
                    ],
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: "unset",
                    boxShadow: "unset",
                },
                outlined: {
                    borderColor: palette.grey["300"],
                    borderWidth: "1px",
                    borderStyle: "solid",
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: "0.875rem",
                    fontWeight: 400,
                    lineHeight: 1.15,
                    textTransform: "uppercase",
                    color: palette.grey["900"],

                    "&.Mui-focused": {
                        color: palette.grey["900"],
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    fontSize: "1rem",
                    fontWeight: 400,
                    lineHeight: 1.25,
                    borderRadius: "unset",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderTopLeftRadius: "unset",
                    borderTopRightRadius: "unset",

                    "label + &": {
                        marginTop: "0.5rem",
                    },
                    "& input": {
                        backgroundColor: palette.white.main,
                        border: `1px solid ${palette.grey["300"]}`,
                        paddingTop: "0.5rem",
                        paddingBottom: "0.5rem",
                    },
                    "&.MuiFilledInput-root:before": {
                        borderBottom: `1px solid ${palette.grey["300"]}`,
                    },
                },
            },
        },
        MuiDatePicker: {
            defaultProps: {
                slotProps: {
                    textField: {
                        sx: {
                            ".MuiInputBase-root": {
                                borderRadius: "unset",
                            },
                            ".MuiInputBase-root input": {
                                border: "none",
                            },
                        },
                    },
                },
            },
        },
        MuiDayCalendar: {
            styleOverrides: {
                root: {
                    ".MuiPickersDay-root.Mui-selected": {
                        backgroundColor: palette.green.main,
                        color: palette.white.main,
                        fontWeight: 700,
                    },
                    ".MuiPickersDay-root:hover": {
                        backgroundColor: palette.green.main,
                        color: palette.white.main,
                        fontWeight: 700,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    position: "relative",
                    fontSize: "0.875rem",
                    transform: "none",
                },
                shrink: {
                    fontSize: "0.875rem",
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                InputLabelProps: {
                    shrink: true,
                },
            },
            styleOverrides: {
                root: {
                    width: "100%",
                    borderRadius: "unset",
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    width: "100%",
                    borderRadius: "unset",
                },
                filled: {
                    paddingTop: "0.5rem",
                    paddingBottom: "0.5rem",
                    backgroundColor: palette.white.main,
                    border: `1px solid ${palette.grey["300"]}`,
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    color: palette.primary.main,
                },
                thumb: {
                    color: palette.primary.main,
                    "&:hover, &.Mui-focusVisible": {
                        boxShadow: `0 0 6px ${palette.primary.main}`,
                    },
                },
                track: {
                    backgroundColor: palette.grey["300"],
                    height: "0.25rem",
                    border: "unset",
                },
                rail: {
                    backgroundColor: palette.grey["300"],
                    height: "0.25rem",
                    opacity: 1,
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    "&.Mui-checked": {
                        color: palette.primary.main,
                    },
                    "& .MuiSvgIcon-root": {
                        fontSize: "1.5rem",
                    },
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: 42,
                    height: 24,
                    padding: 0,
                    "& .MuiSwitch-switchBase": {
                        padding: 0,
                        margin: 1,
                        transitionDuration: "300ms",
                        color: palette.grey["500"],
                        border: "none",
                        "&.Mui-checked": {
                            transform: "translateX(18px)",
                            color: palette.white.main,
                            borderColor: palette.primary.main,
                            "& + .MuiSwitch-track": {
                                backgroundColor: palette.primary.main,
                                borderColor: palette.primary.main,
                                opacity: 1,
                            },
                            "&.Mui-disabled + .MuiSwitch-track": {
                                opacity: 0.5,
                            },
                        },
                        "& + .MuiSwitch-track": {
                            borderRadius: 24 / 2,
                            backgroundColor: palette.white.main,
                            opacity: 1,
                            border: `1px solid ${palette.grey[500]}`,
                        },
                        "&.Mui-focusVisible .MuiSwitch-thumb": {
                            color: palette.blue.light,
                        },
                        "&.Mui-disabled .MuiSwitch-thumb": {
                            color: palette.grey["400"],
                        },
                        "&.Mui-disabled + .MuiSwitch-track": {
                            opacity: 0.7,
                        },
                    },
                    "& .MuiSwitch-thumb": {
                        boxSizing: "border-box",
                        width: 20,
                        height: 20,
                        margin: "1px",
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    "& .MuiTabs-flexContainer": {
                        justifyContent: "start",
                        gap: "2rem",
                        padding: "0 1rem",
                    },

                    "& .MuiTabs-indicator": {
                        backgroundColor: palette.primary.main,
                        height: "0.375rem",
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: "1rem",
                    fontWeight: 400,
                    textTransform: "none",
                    color: palette.black.main,
                    minWidth: "unset",
                    padding: "1.5rem 0.5rem",
                    "&.Mui-selected": {
                        color: palette.black.main,
                    },
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    border: "1px solid",
                    borderColor: palette.grey["300"],
                    variants: [
                        {
                            props: { variant: "simple" },
                            style: {
                                border: "1px solid",
                                borderColor: palette.grey["300"],
                            },
                        },
                    ],
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    border: "1px solid",
                    borderColor: palette.grey["300"],
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.offWhite,
                },
            },
        },
    },
});

export default theme;
