import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import {
    AccordionDetails,
    AccordionSummary,
    Accordion as MuiAccordion,
    type SxProps,
    type Theme,
    type AccordionProps,
} from "@mui/material";
import { useId, useState } from "react";

type Props = AccordionProps & {
    startOpen?: boolean;
    summary: React.ReactNode;
    icon?: {
        open: React.ReactNode;
        close: React.ReactNode;
    };
    children: React.ReactNode;
    detailsSx?: SxProps<Theme>;
};

const Accordion: React.FC<Props> = ({ children, summary, icon, startOpen, detailsSx, ...props }) => {
    const id = useId();
    const [open, setOpen] = useState<boolean>(Boolean(startOpen));

    return (
        <MuiAccordion expanded={open} onChange={(_event, expanded) => setOpen(expanded)} {...props}>
            <AccordionSummary
                expandIcon={open ? icon?.close || <RemoveCircleOutline /> : icon?.open || <AddCircleOutline />}
                aria-controls={`panel-${id}-content`}
                id={`panel-${id}-header`}
            >
                {summary}
            </AccordionSummary>
            <AccordionDetails sx={detailsSx}>{children}</AccordionDetails>
        </MuiAccordion>
    );
};

export default Accordion;
