import { useCallback, useMemo, useState } from "react";
import type { ElectricVehicle, GasVehicle, NearbyVehicle, VehicleTypeType } from "../../types";
import { VehicleCacheContext } from "./VehicleCacheContext";

type CacheKeysType = Record<VehicleTypeType, string | undefined>;

export default function VehicleCacheProvider({ children }: { children: JSX.Element }): JSX.Element {
    const [status, setStatus] = useState<"idle" | "loading" | "error">("idle");
    const [error, setError] = useState<string>();
    const [gasVehicles, setGasVehicles] = useState<GasVehicle[]>();
    const [electricVehicles, setElectricVehicles] = useState<ElectricVehicle[]>();
    const [nearbyVehicles, setNearbyVehicles] = useState<NearbyVehicle[]>();
    const [cacheKeys, setCacheKeys] = useState<CacheKeysType>({
        electric: undefined,
        gas: undefined,
        nearby: undefined,
    });

    const setVehicles = (
        vehicleType: VehicleTypeType,
        vehicles: ElectricVehicle[] | GasVehicle[] | NearbyVehicle[] | undefined,
    ) => {
        switch (vehicleType) {
            case "electric": {
                setElectricVehicles(vehicles as ElectricVehicle[] | undefined);
                break;
            }
            case "gas": {
                setGasVehicles(vehicles as GasVehicle[] | undefined);
                break;
            }
            case "nearby": {
                setNearbyVehicles(vehicles as NearbyVehicle[] | undefined);
                break;
            }
            default: {
                break;
            }
        }
    };

    const fetchVehicles = useCallback(
        async (
            fetchFunction: () => Promise<ElectricVehicle[] | GasVehicle[] | NearbyVehicle[] | undefined>,
            vehicleType: VehicleTypeType,
            cacheKey = "",
        ) => {
            if (!error && cacheKeys[vehicleType] === cacheKey) {
                return;
            }

            setStatus("loading");

            try {
                setVehicles(vehicleType, await fetchFunction());
                setStatus("idle");
                setError(undefined);
                setCacheKeys((currentCacheKey) => ({
                    ...currentCacheKey,
                    [vehicleType]: cacheKey,
                }));
            } catch (err) {
                console.error(err);
                setVehicles(vehicleType, undefined);
                setStatus("error");
                setError("Could not load vehicles");
            }
        },
        [cacheKeys, error],
    );

    const value = useMemo(
        () => ({
            status,
            error,
            gasVehicles,
            electricVehicles,
            nearbyVehicles,
            fetchVehicles,
        }),
        [status, error, gasVehicles, electricVehicles, nearbyVehicles, fetchVehicles],
    );
    return <VehicleCacheContext.Provider value={value}>{children}</VehicleCacheContext.Provider>;
}
