import { Box, MenuItem, MenuList, PopoverOrigin, Typography, useTheme } from "@mui/material";
import { useIntl } from "@src/utils/hooks/useIntl";
import { useIsMobilePhone } from "@src/utils/hooks/useIsMobilePhone";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import { bindHover, bindPopover, usePopupState } from "material-ui-popup-state/hooks";
import React, { useMemo } from "react";
import Chevron from "./Chevron";
import HeaderLink from "./HeaderLink";
import { IHeader, IHeaderItem } from "./types";

interface IProps {
    header: IHeader;
    popupId?: string;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
}

const useTitle = (header: IHeader | IHeaderItem) => {
    const isMobilePhone = useIsMobilePhone();

    return useMemo(() => {
        if (isMobilePhone && typeof header.title === "string" && header.title.endsWith(".main")) {
            return header.title.replace(".main", ".mobile.main");
        } else {
            return header.title;
        }
    }, [header.title, isMobilePhone]);
};

const HeaderPopoverMenu: React.FC<IProps> = ({
    header,
    anchorOrigin = {
        vertical: "bottom",
        horizontal: "center",
    },
    transformOrigin = {
        vertical: "top",
        horizontal: "center",
    },
    popupId = "headerPopover",
}) => {
    const {
        palette: { primary },
    } = useTheme();
    const { formatMessageString } = useIntl();
    const title = useTitle(header);

    const popupState = usePopupState({
        variant: "popover",
        popupId: popupId,
    });

    return (
        <Box>
            <Typography
                component="div"
                aria-haspopup="true"
                {...bindHover(popupState)}
                sx={{
                    ...header.sx,
                    cursor: "pointer",
                    fontWeight: "bold",
                    px: { xs: 0, sm: 1, md: 2 },
                    fontSize: { xs: "14px", md: "16px" },
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {typeof title === "string" ? formatMessageString({ id: title }) : title}{" "}
                {typeof title === "string" && <Chevron fill={primary.main} />}
            </Typography>

            <HoverPopover
                {...bindPopover(popupState)}
                disableScrollLock // https://github.com/jcoreio/material-ui-popup-state/issues/37#issuecomment-1284088304
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                slotProps={{
                    paper: {
                        sx: {
                            border: "1px solid",
                            borderColor: "grey.400",
                        },
                    },
                }}
            >
                <MenuList sx={{ textAlign: "left" }} style={{ padding: 0 }}>
                    {(header.items ?? []).map((headerItem, idx) => [
                        <MenuItem
                            key={headerItem.href}
                            sx={{
                                p: 0,
                                textAlign: "left",
                            }}
                        >
                            {headerItem.items ? (
                                <HeaderPopoverSubMenu
                                    key={`${headerItem.title}-item-${idx}`}
                                    header={headerItem}
                                    popupId={`${headerItem.title}-popup`}
                                />
                            ) : (
                                <HeaderLink header={headerItem} href={headerItem.href as string} />
                            )}
                        </MenuItem>,
                    ])}
                </MenuList>
            </HoverPopover>
        </Box>
    );
};

const HeaderPopoverSubMenu: React.FC<{ header: IHeaderItem; popupId: string }> = ({ header, popupId }) => {
    const { formatMessageString } = useIntl();
    const title = useTitle(header);

    const anchorOrigin = {
        vertical: "bottom",
        horizontal: "right",
    } as PopoverOrigin;

    const transformOrigin = {
        vertical: "center",
        horizontal: "left",
    } as PopoverOrigin;

    const popupState = usePopupState({
        variant: "popover",
        popupId: popupId,
    });

    return (
        <>
            {header.href && header.items && (
                <Typography
                    component="div"
                    aria-haspopup="true"
                    {...bindHover(popupState)}
                    sx={{
                        ...header.sx,
                        cursor: "pointer",
                        // fontWeight: 'bold',
                        // px: { xs: 0, sm: 1, md: 2 },
                        // fontSize: { xs: '14px', md: '16px' },
                        display: "flex",
                        alignItems: "center",

                        fontSize: "16px",
                        lineHight: "23.68px",
                        color: "common.black",
                        fontWeight: 600,
                        border: 0,
                        borderRadius: 0,
                        px: 2,
                        py: 3,
                        width: "100%",
                        justifyContent: "flex-start",
                    }}
                >
                    {typeof title === "string" ? formatMessageString({ id: title }) : title}
                </Typography>
            )}

            <HoverPopover
                {...bindPopover(popupState)}
                disableScrollLock // https://github.com/jcoreio/material-ui-popup-state/issues/37#issuecomment-1284088304
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                slotProps={{
                    paper: {
                        sx: {
                            border: "1px solid",
                            borderColor: "grey.400",
                        },
                    },
                }}
            >
                <MenuList sx={{ textAlign: "left" }} style={{ padding: 0 }}>
                    {(header.items ?? []).map((item) => [
                        <MenuItem
                            key={item.href}
                            sx={{
                                p: 0,
                                textAlign: "left",
                            }}
                        >
                            <HeaderLink header={item} href={item.href as string} />
                        </MenuItem>,
                    ])}
                </MenuList>
            </HoverPopover>
        </>
    );
};

export default HeaderPopoverMenu;
