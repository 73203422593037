import type { ElectricVehicle } from "@src/types";
import { useSearch } from "./useSearch";
import useElectricVehicles from "@src/api/hooks/useElectricVehicles";

const vehicleIndexFields: (keyof ElectricVehicle)[] = [
    "handle",
    "fuelType",
    "make",
    "model",
    "type",
    "trim",
    "subtype",
    "drivetrain",
    "modelYear",
];

const vehicleSynonymGroups = {
    car: ["vehicle", "auto", "automobile"],
};

export const useVehicleSearch = (searchPhrase: string) => {
    const { allElectricVehicles: vehiclesFromApi } = useElectricVehicles(true);
    const { results: vehicles } = useSearch<ElectricVehicle>(searchPhrase, vehiclesFromApi, {
        indexFields: vehicleIndexFields,
        synonymGroups: vehicleSynonymGroups,
    });

    return { vehicles };
};
