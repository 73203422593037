// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

/*!
 * FitText.js 1.0 jQuery free version
 *
 * Copyright 2011, Dave Rupert http://daverupert.com
 * Released under the WTFPL license
 * http://sam.zoy.org/wtfpl/
 * Modified by Slawomir Kolodziej http://slawekk.info
 * https://raw.githubusercontent.com/adactio/FitText.js/refs/heads/master/fittext.js
 *
 * Date: Tue Aug 09 2011 10:45:54 GMT+0200 (CEST)
 */
const addEvent = function (el, type, fn) {
    if (el.addEventListener) el.addEventListener(type, fn, false);
    else el.attachEvent("on" + type, fn);
};

const extend = function (obj, ext) {
    // eslint-disable-next-line no-prototype-builtins
    for (const key in ext) if (ext.hasOwnProperty(key)) obj[key] = ext[key];
    return obj;
};

export const textFit = function (el, compressor = 1, options = {}) {
    if (!el) return null;

    const settings = extend(
        {
            minFontSize: -1 / 0,
            maxFontSize: 1 / 0,
        },
        options,
    );

    const fit = function (el_) {
        const resizer = function () {
            el_.style.fontSize =
                Math.max(
                    Math.min(el_.clientWidth / (compressor * 10), parseFloat(settings.maxFontSize)),
                    parseFloat(settings.minFontSize),
                ) + "px";
        };

        // Call once to set.
        resizer();

        // Bind events
        // If you have any js library which support Events, replace this part
        // and remove addEvent function (or use original jQuery version)
        addEvent(window, "resize", resizer);
        addEvent(window, "orientationchange", resizer);
    };

    if (el.length) {
        for (const el_ of el) {
            fit(el_);
        }
    } else fit(el);

    // return set of elements
    return el;
};
