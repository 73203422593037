import GoogleAnalytics from "react-ga4";
import type GoogleAnalyticsType from "react-ga4/types";
import type { GaOptions, UaEventOptions, InitOptions } from "react-ga4/types/ga4";

// react-ga4 allows an any type for gaOptions, which makes InitOptions much less useful for ensuring correctness.
type InitOptionsWithoutGaOptionsAsAnyType = Omit<InitOptions, "gaOptions"> & {
    gaOptions?: GaOptions;
};
// So we can merge the default static tracker options with dynamically supplied options like userId
type FullyOptionalInitOptions = Omit<InitOptionsWithoutGaOptionsAsAnyType, "trackingId"> & {
    nonce?: string;
    trackingId?: string;
};

const TypedGoogleAnalytics = GoogleAnalytics as typeof GoogleAnalyticsType;

const trackers: InitOptionsWithoutGaOptionsAsAnyType[] = [];
switch (import.meta.env.VITE_ENVIRONMENT) {
    case "production":
        trackers.push({
            trackingId: import.meta.env.VITE_GA_CLIENT_PRODUCTION_TRACKING_ID,
            gaOptions: {
                contentGroup1: import.meta.env.VITE_GA_CLIENT_PRODUCTION_TRACKING_OPTIONS_NAME,
            },
        });
        trackers.push({
            trackingId: import.meta.env.VITE_GA_ZAPPYRIDE_PRODUCTION_TRACKING_ID,
            gaOptions: {
                contentGroup1: import.meta.env.VITE_GA_ZAPPYRIDE_PRODUCTION_TRACKING_OPTIONS_NAME,
            },
        });
        break;
    case "staging":
        trackers.push({
            trackingId: import.meta.env.VITE_GA_CLIENT_STAGING_TRACKING_ID,
            gaOptions: {
                contentGroup1: import.meta.env.VITE_GA_CLIENT_STAGING_TRACKING_OPTIONS_NAME,
            },
        });
        trackers.push({
            trackingId: import.meta.env.VITE_GA_ZAPPYRIDE_STAGING_TRACKING_ID,
            gaOptions: {
                contentGroup1: import.meta.env.VITE_GA_ZAPPYRIDE_STAGING_TRACKING_OPTIONS_NAME,
            },
        });
        break;
    default:
}

const GaTracker = {
    initialize: (dynamicOptions: FullyOptionalInitOptions) => {
        if (trackers.length) {
            TypedGoogleAnalytics.initialize(
                trackers.map(
                    (defaultOptions): InitOptionsWithoutGaOptionsAsAnyType => ({
                        ...defaultOptions,
                        ...dynamicOptions,
                    }),
                ),
            );
        }
    },
    // react-ga4 will accept a string event, but only in order to support the same syntax used for the UA focused library.
    // Better to use the UaEventOptions type to ensure we're using the correct syntax for GA4.
    trackEvent: (event: UaEventOptions, options = {}) => {
        trackers.forEach(() => {
            if (options.constructor === Object && Object.keys(options).length > 0) {
                TypedGoogleAnalytics.set({
                    ...options,
                });
            }
            TypedGoogleAnalytics.event(event);
        });
    },
};

export default GaTracker;
