import type { Incentive, ApiIncentive } from "./incentive";

export type ApiVehicleIncentive = ApiIncentive & {
    evaluation: {
        applicable_to_lease: boolean;
        applicable_to_ownership: boolean;
        capitalized_in_lease: boolean;
        eligibility: "eligible" | "ineligible" | null;
        amount_in_lease: number;
        amount_in_purchase: number;
        amount_in_lease_precision: string;
        amount_in_purchase_precision: string;
        lease_calculation_rationale: string;
        purchase_calculation_rationale: string;
    };
};

export type VehicleIncentive = Incentive & {
    evaluation: {
        applicableToLease: boolean;
        applicableToOwnership: boolean;
        capitalizedInLease: boolean;
        eligibility: "eligible" | "ineligible" | null;
        amountInLease: number;
        amountInPurchase: number;
        amountInLeasePrecision: string;
        amountInPurchasePrecision: string;
        leaseCalculationRationale: string;
        purchaseCalculationRationale: string;
    };
};

export const isVehicleIncentive = (incentive: VehicleIncentive | Incentive): incentive is VehicleIncentive => {
    return "evaluation" in incentive;
};
