import type { ApiVehicleIncentive, VehicleIncentive } from "../../types/vehicleIncentive";
import { transformApiIncentive } from "./transformIncentive";
import type { ApiLanguage } from "@src/constants/apiLanguages";

export const transformVehicleIncentive = (
    apiVehicleIncentive: ApiVehicleIncentive,
    apiLanguage: ApiLanguage,
): VehicleIncentive => {
    return {
        ...transformApiIncentive(apiVehicleIncentive, apiLanguage),
        evaluation: {
            applicableToLease: apiVehicleIncentive.evaluation.applicable_to_lease,
            applicableToOwnership: apiVehicleIncentive.evaluation.applicable_to_ownership,
            capitalizedInLease: apiVehicleIncentive.evaluation.capitalized_in_lease,
            eligibility: apiVehicleIncentive.evaluation.eligibility || "ineligible",
            amountInLease: apiVehicleIncentive.evaluation.amount_in_lease,
            amountInPurchase: apiVehicleIncentive.evaluation.amount_in_purchase,
            amountInLeasePrecision: apiVehicleIncentive.evaluation.amount_in_lease_precision,
            amountInPurchasePrecision: apiVehicleIncentive.evaluation.amount_in_purchase_precision,
            leaseCalculationRationale: apiVehicleIncentive.evaluation.lease_calculation_rationale,
            purchaseCalculationRationale: apiVehicleIncentive.evaluation.purchase_calculation_rationale,
        },
    };
};
