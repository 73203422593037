import type { ApiIncentive, Incentive } from "@src/types/incentive";
import { transformApiToLanguage, type ApiLanguage } from "@src/constants/apiLanguages";

export const transformApiIncentive = (apiIncentive: ApiIncentive, apiLanguage: ApiLanguage): Incentive => {
    return {
        zrId: apiIncentive.id,
        language: transformApiToLanguage(apiLanguage),
        grantor: apiIncentive.grantor,
        grantorType: apiIncentive.grantor_type,
        applicantType: apiIncentive.applicant_type,
        type: apiIncentive.type,
        name: apiIncentive.name,
        description: apiIncentive.description,
        typicalAmount: apiIncentive.typical_amount,
        limitations: apiIncentive.limitations,
        available: apiIncentive.available,
        detailsUrl: apiIncentive.details_url,
        supportFor: apiIncentive.support_for,
        applicableToNewVehicles: apiIncentive.applicable_to_new_vehicles,
        applicableToUsedVehicles: apiIncentive.applicable_to_used_vehicles,
        applicableToFcevs: apiIncentive.applicable_to_fcevs,
        incomeDependent: apiIncentive.income_dependent,
        maxAmount: apiIncentive.max_amount,
        pointOfSale: apiIncentive.point_of_sale,
        supportSubtype: "support_subtype" in apiIncentive ? [...apiIncentive.support_subtype] : [],
    };
};
