import { Box, Typography } from "@mui/material";
import Screen from "@src/components/screen";
import type { ReactNode } from "react";

interface Props {
    children?: ReactNode;
}

export default function NotFound({ children }: Props) {
    return (
        <Screen>
            <Box bgcolor="grey.100" flex="1">
                <Box maxWidth="1140px" mx="auto" p="60px 15px">
                    <Typography variant="h1">Page Not Found</Typography>
                    {children}
                </Box>
            </Box>
        </Screen>
    );
}
