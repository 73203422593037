import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

import { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import ReactIntlProvider from "@context/ReactIntlProvider";
import UserPrefsProvider from "@src/context/userPrefs/UserPrefsProvider";
import VehicleCacheProvider from "@src/context/vehicles/VehicleCacheProvider";
import ThemeProvider from "@theme/ThemeProvider";
import useScrollToTop from "@utils/useScrollToTop";

import NotFound from "@screens/error/NotFound";
import Home from "@screens/home";
import ErrorBoundary from "./components/errorBoundary/ErrorBoundary";
import Loading from "./components/loading";
import { ROUTES, useApplicationRoutes } from "./constants/routes";
import { useUuid } from "./api/hooks/useUuid";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import GaTracker from "./utils/GaTracker/GaTracker";

const ChargerCatalog = lazy(() => import("@screens/chargerCatalog"));
const ChargerMap = lazy(() => import("@screens/chargerMap"));
const CompareVehicles = lazy(() => import("@screens/compareVehicles"));
const DealerMap = lazy(() => import("@screens/dealerMap"));
const Incentives = lazy(() => import("@screens/incentives"));
const VehicleDetails = lazy(() => import("@screens/vehicleDetails"));
const VehicleList = lazy(() => import("@screens/vehicleList"));
const BrowseNearbyVehicles = lazy(() => import("@screens/nearby"));

const AppProviders: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const cache = createCache({
        key: "css",
        prepend: true,
        nonce: "m7yvr64n3ob2ci9xdpl",
    });

    return (
        <BrowserRouter>
            {/* TODO: Convert BrowserRouter to createBrowserRouter in order to use ScrollRestoration */}
            {/* https://reactrouter.com/en/main/routers/create-browser-router */}
            {/* <ScrollRestoration /> */}
            <CacheProvider value={cache}>
                <ThemeProvider>
                    <CssBaseline />
                    <ReactIntlProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <VehicleCacheProvider>
                                <UserPrefsProvider>
                                    <ErrorBoundary>{children}</ErrorBoundary>
                                </UserPrefsProvider>
                            </VehicleCacheProvider>
                        </LocalizationProvider>
                    </ReactIntlProvider>
                </ThemeProvider>
            </CacheProvider>
        </BrowserRouter>
    );
};

function AppRoutes() {
    useScrollToTop();

    const routes = useApplicationRoutes();

    const { uuid } = useUuid();
    useEffect(() => {
        GaTracker.initialize({
            nonce: "m7yvr64n3ob2ci9xdpl",
            gaOptions: {
                userId: uuid,
            },
        });
    }, [uuid]);

    const getElement = (Component: React.JSX.ElementType) => {
        return (
            <Suspense fallback={<Loading height="100vh" width="100vw" />}>
                <main>
                    <Component />
                </main>
            </Suspense>
        );
    };

    return (
        <>
            <Routes>
                <Route path={"/"} element={<Navigate to={routes.Root} />} />
                <Route path={ROUTES.Root} element={<Home />} />
                <Route path={ROUTES.VehicleDetails()} element={getElement(VehicleDetails)} />
                <Route path={ROUTES.UsedVehicleDetails()} element={<Navigate replace to={ROUTES.VehicleDetails()} />} />
                <Route path={ROUTES.Vehicles()} element={getElement(VehicleList)} />
                <Route path={ROUTES.NearbyVehicles()} element={getElement(BrowseNearbyVehicles)} />
                <Route path={ROUTES.UsedVehicles()} element={getElement(VehicleList)} />
                <Route path={ROUTES.ChargingStations()} element={getElement(ChargerMap)} />
                <Route path={ROUTES.Dealers()} element={getElement(DealerMap)} />
                <Route path={ROUTES.CompareVehicles()} element={getElement(CompareVehicles)} />
                <Route path={ROUTES.CompareUsedVehicles()} element={getElement(CompareVehicles)} />
                <Route path={ROUTES.Incentives()} element={getElement(Incentives)} />
                <Route path={ROUTES.HomeChargers()} element={getElement(ChargerCatalog)} />

                {/** 404! */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
}

const App: React.FC = () => {
    return (
        <AppProviders>
            <AppRoutes />
        </AppProviders>
    );
};

export default App;
