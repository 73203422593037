import { Link, LinkProps } from "@mui/material";

const ExternalLink: React.FC<LinkProps> = ({ children, rel: _rel, target: _target, ...props }) => {
    return (
        <Link rel="noopener noreferrer" target="_blank" {...props}>
            {children}
        </Link>
    );
};

export default ExternalLink;
