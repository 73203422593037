import { type ApiLanguage, EN } from "@src/constants/apiLanguages";
import { createContext } from "react";

interface LanguageChoiceContextType {
    language: string;
    zrLanguage: ApiLanguage;
    missingIds: string[];
    defaultLanguage: string;
}

export const LanguageChoiceContext = createContext<LanguageChoiceContextType>({
    language: "en",
    zrLanguage: EN,
    missingIds: [],
    defaultLanguage: "en",
});
