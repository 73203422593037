import { Box, Link } from "@mui/material";
import homeFAQEmissionsChart from "@src/assets/images/home-faq-emissions-chart.png";

const FAQ_ACCORDIONS = [
    {
        key: 1,
    },
    {
        key: 2,
        image: (
            <Box
                component="img"
                src={homeFAQEmissionsChart}
                alt="A chart showing CO2 emissions by sector (Industrial, Buildings, Transportation, Other)"
                sx={{
                    width: "100%",
                    my: 2,
                    background: ({ palette }) =>
                        `linear-gradient(90deg, white 0%, ${palette.grey[300]} 20%, ${palette.grey[600]} 90%)`,
                }}
            />
        ),
    },
    {
        key: 3,
    },
    {
        key: 4,
        link: (
            <Link
                href="https://coolcalifornia.arb.ca.gov/calculator-households-individuals"
                target="_blank"
                rel="noopener noreferrer"
            >
                CoolCalifornia.org
            </Link>
        ),
    },
    {
        key: 5,
    },
    {
        key: 6,
    },
    {
        key: 7,
    },
    {
        key: 8,
    },
];

export default FAQ_ACCORDIONS;
