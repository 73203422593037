import { Grid2, Stack, Typography } from "@mui/material";
import BatteryIcon from "@src/assets/images/battery-icon.svg?react";
import DollarIcon from "@src/assets/images/dollar-icon.svg?react";
import FaqIcon from "@src/assets/images/faq-icon.svg?react";
import VehiclesIcon from "@src/assets/images/vehicles-icon.svg?react";
import Button from "@src/components/button";
import { useIntl } from "@src/utils/hooks/useIntl";
import React from "react";

const ANCHORS = [
    {
        Icon: VehiclesIcon,
        href: "#vehicles",
    },
    {
        Icon: DollarIcon,
        href: "#discover",
    },
    {
        Icon: BatteryIcon,
        href: "#charging",
    },
    {
        Icon: FaqIcon,
        href: "#faq",
    },
];

const HomeRebrandAnchors = () => {
    const { formatMessageString } = useIntl();
    return (
        <Grid2 container spacing={2} sx={{ p: 2, maxWidth: "1078px", mx: "auto" }}>
            {ANCHORS.map((anchor, index) => {
                const a = {
                    ...anchor,
                    title: formatMessageString({ id: `screens.home.anchors.${index}` }),
                };
                return (
                    <Grid2
                        key={a.title}
                        size={{ xs: 6, md: 3 }}
                        sx={{
                            display: "flex",
                            flexDirection: "col",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <AnchorCard anchor={a} />
                    </Grid2>
                );
            })}
        </Grid2>
    );
};

const AnchorCard: React.FC<{
    anchor: {
        title: string;
        Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
        href: string;
    };
}> = ({ anchor: { title, Icon, href } }) => {
    return (
        <Button
            variant="outlined"
            color="primary"
            href={href}
            sx={{
                borderRadius: 2,
                backgroundColor: "grey.200",
                border: 0,
                padding: "15px",
                color: "black.main",
                "&:hover": {
                    stroke: "common.white",
                    color: "common.white",
                },
            }}
        >
            <Stack
                direction={"column"}
                gap={2}
                sx={{
                    padding: 3,
                    width: "241px",
                    height: "196px",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Icon
                    style={{
                        width: "60%",
                    }}
                />
                <Typography
                    sx={{
                        fontWeight: "bold",
                    }}
                >
                    {title}
                </Typography>
            </Stack>
        </Button>
    );
};

export default HomeRebrandAnchors;
