import { AppBar, Box, Divider, Grid2, Link, Stack, Typography } from "@mui/material";
import { useLanguageCode } from "@src/utils/hooks/useLanguageCode";
import ButtonLink from "../buttonLink";
import Facebook from "./facebook.svg";
import Instagram from "./instagram.svg";
import Linkedin from "./linkedin.svg";
import Twitter from "./twitter.svg";
import { SVCE_WEBSITE_BASE } from "@src/constants/routes";
import { ChatBubbleOutline } from "@mui/icons-material";
import { useIntl } from "@src/utils/hooks/useIntl";
import ExternalLink from "../externalLink";

export default function Footer() {
    const { formatMessageString } = useIntl();
    const languageCode = useLanguageCode();

    return (
        <AppBar
            component={"footer"}
            sx={{
                backgroundColor: "grey.700",
                color: "white.main",
                px: 10,
                py: 5,
                zIndex: 1,
            }}
        >
            <Grid2 container spacing={4} sx={{ width: "100%" }}>
                <Grid2 size={{ xs: 12, sm: 6, lg: 3 }} sx={{ textAlign: { xs: "center", md: "unset" } }}>
                    <Box>
                        <Typography variant="h2" component={"h2"}>
                            {formatMessageString({ id: "footer.contact.title" })}
                        </Typography>
                        <Divider
                            sx={{
                                backgroundColor: "common.white",
                                width: "100px",
                                height: "3px",
                                my: 3,
                                mx: { xs: "auto", md: "unset" },
                            }}
                        />
                        <Stack direction={"column"}>
                            <ExternalLink
                                sx={{
                                    color: "common.white",
                                    textDecoration: "none",
                                    "&:hover": { textDecoration: "underline" },
                                }}
                                href="https://www.google.com/maps/place/333+W+El+Camino+Real+%23330,+Sunnyvale,+CA+94087/@37.3693137,-122.0380819,17z/data=!3m1!4b1!4m5!3m4!1s0x808fb661a8aaf46d:0x4ff90ff1dd52c5eb!8m2!3d37.3693137!4d-122.0358879"
                            >
                                <Typography mb={3}>
                                    333 W. EL CAMINO REAL
                                    <br />
                                    #330
                                    <br />
                                    SUNNYVALE, CA 94087
                                </Typography>
                            </ExternalLink>
                            <ExternalLink
                                sx={{
                                    color: "common.white",
                                    textDecoration: "none",
                                    "&:hover": { textDecoration: "underline" },
                                }}
                                href="tel:8444747823"
                            >
                                <Typography mb={3}>1-844-474-SVCE (7823)</Typography>
                            </ExternalLink>
                            <ExternalLink
                                sx={{
                                    color: "common.white",
                                    textDecoration: "none",
                                    "&:hover": { textDecoration: "underline" },
                                }}
                                href="mailto:info@svcleanenergy.org"
                            >
                                <Typography mb={3}>INFO@SVCLEANENERGY.COM</Typography>
                            </ExternalLink>
                            <ButtonLink
                                style={{ backgroundColor: "white" }}
                                sx={{
                                    color: "secondary.dark",
                                    borderRadius: 5,
                                    mb: 3,
                                    width: { md: "60%", lg: "90%" },
                                    mx: { xs: "auto", md: "unset" },
                                }}
                                variant="contained"
                                href={`${SVCE_WEBSITE_BASE}/${languageCode}/contact/`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {formatMessageString({ id: "footer.contact.title" })}{" "}
                                <ChatBubbleOutline fontSize="small" sx={{ ml: 1 }} />
                            </ButtonLink>
                            <Stack
                                direction="row"
                                gap={2}
                                mb={{ xs: 6, md: 0 }}
                                sx={{ mx: { xs: "auto", md: "unset" } }}
                            >
                                <Box>
                                    <ExternalLink
                                        sx={{
                                            color: "common.white",
                                            textDecoration: "none",
                                            "&:hover": { textDecoration: "underline" },
                                        }}
                                        href="https://www.facebook.com/svcleanenergy/"
                                    >
                                        <img src={Facebook} alt="Facebook" />
                                    </ExternalLink>
                                </Box>
                                <Box>
                                    <ExternalLink
                                        sx={{
                                            color: "common.white",
                                            textDecoration: "none",
                                            "&:hover": { textDecoration: "underline" },
                                        }}
                                        href="https://twitter.com/svcleanenergy/"
                                    >
                                        <img src={Twitter} alt="Twitter" />
                                    </ExternalLink>
                                </Box>
                                <Box>
                                    <ExternalLink
                                        sx={{
                                            color: "common.white",
                                            textDecoration: "none",
                                            "&:hover": { textDecoration: "underline" },
                                        }}
                                        href="https://www.instagram.com/svcleanenergy/"
                                    >
                                        <img src={Instagram} alt="Instagram" />
                                    </ExternalLink>
                                </Box>
                                <Box>
                                    <ExternalLink
                                        sx={{
                                            color: "common.white",
                                            textDecoration: "none",
                                            "&:hover": { textDecoration: "underline" },
                                        }}
                                        href="https://www.linkedin.com/company/silicon-valley-clean-energy"
                                    >
                                        <img src={Linkedin} alt="Linkedin" />
                                    </ExternalLink>
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>
                </Grid2>
                <Grid2
                    size={{ xs: 12, sm: 6, lg: 3 }}
                    sx={{ textAlign: { xs: "center", md: "left" }, my: { xs: 5, md: "unset" } }}
                >
                    <Box>
                        <Typography variant="h2" component={"h2"}>
                            {formatMessageString({ id: "footer.moreInfo.title" })}
                        </Typography>
                        <Divider
                            sx={{
                                backgroundColor: "common.white",
                                width: "100px",
                                height: "3px",
                                my: 3,
                                mx: { xs: "auto", md: "unset" },
                            }}
                        />
                        <Stack>
                            <ExternalLink
                                sx={{
                                    color: "common.white",
                                    textDecoration: "none",
                                    "&:hover": { textDecoration: "underline" },
                                }}
                                href="https://svcleanenergy.org/faqs/"
                            >
                                <Typography mb={3}>{formatMessageString({ id: "footer.moreInfo.faqs" })}</Typography>
                            </ExternalLink>
                            <ExternalLink
                                sx={{
                                    color: "common.white",
                                    textDecoration: "none",
                                    "&:hover": { textDecoration: "underline" },
                                }}
                                href="https://svcleanenergy.org/news-articles/"
                            >
                                <Typography mb={3}>{formatMessageString({ id: "footer.moreInfo.news" })}</Typography>
                            </ExternalLink>
                            <ExternalLink
                                sx={{
                                    color: "common.white",
                                    textDecoration: "none",
                                    "&:hover": { textDecoration: "underline" },
                                }}
                                href="https://svcleanenergy.org/jobs/"
                            >
                                <Typography mb={3}>{formatMessageString({ id: "footer.moreInfo.join" })}</Typography>
                            </ExternalLink>
                            <ExternalLink
                                sx={{
                                    color: "common.white",
                                    textDecoration: "none",
                                    "&:hover": { textDecoration: "underline" },
                                }}
                                href="https://svcleanenergy.org/dream-home/"
                            >
                                <Typography mb={3}>{formatMessageString({ id: "footer.moreInfo.dream" })}</Typography>
                            </ExternalLink>
                        </Stack>
                    </Box>
                </Grid2>
                <Grid2
                    size={{ xs: 12, sm: 6, lg: 3 }}
                    sx={{ textAlign: { xs: "center", md: "left" }, my: { xs: 5, md: "unset" } }}
                >
                    <Box>
                        <Typography variant="h2" component={"h2"}>
                            {formatMessageString({ id: "footer.community.title" })}
                        </Typography>
                        <Divider
                            sx={{
                                backgroundColor: "common.white",
                                width: "100px",
                                height: "3px",
                                my: 3,
                                mx: { xs: "auto", md: "unset" },
                            }}
                        />
                        <Stack>
                            <ExternalLink
                                sx={{
                                    color: "common.white",
                                    textDecoration: "none",
                                    "&:hover": { textDecoration: "underline" },
                                }}
                                href="https://svcleanenergy.org/member-agencies/"
                            >
                                <Typography mb={3}>{formatMessageString({ id: "footer.community.agency" })}</Typography>
                            </ExternalLink>
                            <ExternalLink
                                sx={{
                                    color: "common.white",
                                    textDecoration: "none",
                                    "&:hover": { textDecoration: "underline" },
                                }}
                                href="https://svcleanenergy.org/grant-sponsorships/"
                            >
                                <Typography mb={3}>
                                    {formatMessageString({ id: "footer.community.education" })}
                                </Typography>
                            </ExternalLink>
                            <ExternalLink
                                sx={{
                                    color: "common.white",
                                    textDecoration: "none",
                                    "&:hover": { textDecoration: "underline" },
                                }}
                                href="https://svcleanenergy.org/reach-codes/"
                            >
                                <Typography mb={3}>
                                    {formatMessageString({ id: "footer.community.policies" })}
                                </Typography>
                            </ExternalLink>
                            <Box
                                sx={{
                                    "&:before": {
                                        content: '""',
                                    },
                                    mx: { xs: "auto", md: "unset" },
                                }}
                            >
                                <Link
                                    id="bbblink"
                                    href="https://www.bbb.org/us/ca/sunnyvale/profile/electric-companies/silicon-valley-clean-energy-1216-717350"
                                    title="Silicon Valley Clean Energy is a BBB Accredited Electric Company in Sunnyvale, CA"
                                    style={{
                                        padding: 0,
                                        margin: 0,
                                        position: "relative",
                                        width: "200px",
                                        height: "90px",
                                        overflow: "hidden",
                                        display: "block",
                                    }}
                                >
                                    <img
                                        style={{
                                            padding: 0,
                                            border: "medium",
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            marginLeft: 0,
                                            maxWidth: "200%",
                                        }}
                                        id="bbblinkimg"
                                        src="https://seal-sanjose.bbb.org/logo/ruhzbul/silicon-valley-clean-energy-717350.png"
                                        width="400"
                                        height="90"
                                        alt="Silicon Valley Clean Energy is a BBB Accredited Electric Company in Sunnyvale, CA"
                                    />
                                </Link>
                            </Box>
                        </Stack>
                    </Box>
                </Grid2>
                <Grid2
                    size={{ xs: 12, sm: 6, lg: 3 }}
                    sx={{ textAlign: { xs: "center", md: "left" }, my: { xs: 5, md: "unset" } }}
                >
                    <Box>
                        <Typography variant="h2" component={"h2"}>
                            {formatMessageString({ id: "footer.business.title" })}
                        </Typography>
                        <Divider
                            sx={{
                                backgroundColor: "common.white",
                                width: "100px",
                                height: "3px",
                                my: 3,
                                mx: { xs: "auto", md: "unset" },
                            }}
                        />
                        <Stack>
                            <ExternalLink
                                sx={{
                                    color: "common.white",
                                    textDecoration: "none",
                                    "&:hover": { textDecoration: "underline" },
                                }}
                                href="https://svcleanenergy.org/solicitations/"
                            >
                                <Typography mb={3}>
                                    {formatMessageString({ id: "footer.business.solicitations" })}
                                </Typography>
                            </ExternalLink>
                            <ExternalLink
                                sx={{
                                    color: "common.white",
                                    textDecoration: "none",
                                    "&:hover": { textDecoration: "underline" },
                                }}
                                href="https://svcleanenergy.org/supplier-diversity/"
                            >
                                <Typography mb={3}>
                                    {formatMessageString({ id: "footer.business.diversity" })}
                                </Typography>
                            </ExternalLink>
                            <ExternalLink
                                sx={{
                                    color: "common.white",
                                    textDecoration: "none",
                                    "&:hover": { textDecoration: "underline" },
                                }}
                                href="https://svcleanenergy.org/media-center/"
                            >
                                <Typography mb={3}>{formatMessageString({ id: "footer.business.media" })}</Typography>
                            </ExternalLink>
                            <ExternalLink
                                sx={{
                                    color: "common.white",
                                    textDecoration: "none",
                                    "&:hover": { textDecoration: "underline" },
                                }}
                                href="https://svcleanenergy.org/innovation/"
                            >
                                <Typography mb={3}>
                                    {formatMessageString({ id: "footer.business.innovation" })}
                                </Typography>
                            </ExternalLink>
                            <ExternalLink
                                sx={{
                                    color: "common.white",
                                    textDecoration: "none",
                                    "&:hover": { textDecoration: "underline" },
                                }}
                                href="https://svcleanenergy.org/researchandanalysis/"
                            >
                                <Typography mb={3}>
                                    {formatMessageString({ id: "footer.business.research" })}
                                </Typography>
                            </ExternalLink>
                            <ExternalLink
                                sx={{
                                    color: "common.white",
                                    textDecoration: "none",
                                    "&:hover": { textDecoration: "underline" },
                                }}
                                href="https://data.svcleanenergy.org/"
                            >
                                <Typography mb={3}>{formatMessageString({ id: "footer.business.hive" })}</Typography>
                            </ExternalLink>
                        </Stack>
                    </Box>
                </Grid2>
            </Grid2>
            <Divider sx={{ borderColor: "secondary.light", borderWidth: 1, width: "100%", my: 3 }} />
            <Grid2 container sx={{ width: "100%" }}>
                <Grid2
                    size={{ xs: 12, sm: 6 }}
                    sx={{
                        textAlign: { xs: "center", md: "left" },
                    }}
                >
                    <Typography variant="caption">{formatMessageString({ id: "footer.copyright" })}</Typography>
                </Grid2>
                <Grid2
                    size={{ xs: 12, sm: 3 }}
                    sx={{
                        textAlign: { xs: "center", md: "left" },
                    }}
                >
                    <ExternalLink
                        sx={{
                            color: "common.white",
                            textDecoration: "none",
                            "&:hover": { textDecoration: "underline" },
                        }}
                        href="https://svcleanenergy.org/accessibility/"
                    >
                        <Typography variant="caption">{formatMessageString({ id: "footer.accessibility" })}</Typography>
                    </ExternalLink>
                </Grid2>
                <Grid2
                    size={{ xs: 12, sm: 3 }}
                    sx={{
                        textAlign: { xs: "center", md: "left" },
                    }}
                >
                    <ExternalLink
                        sx={{
                            color: "common.white",
                            textDecoration: "none",
                            "&:hover": { textDecoration: "underline" },
                        }}
                        href="https://svcleanenergy.org/customer-confidentiality/"
                    >
                        <Typography variant="caption">
                            {formatMessageString({ id: "footer.confidentiality" })}
                        </Typography>
                    </ExternalLink>
                </Grid2>
            </Grid2>
        </AppBar>
    );
}
