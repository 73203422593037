import Launch from "@mui/icons-material/Launch";
import { Link as MuiLink, Typography } from "@mui/material";
import { SVCE_WEBSITE_BASE } from "@src/constants/routes";
import { useIntl } from "@src/utils/hooks/useIntl";
import { useLanguageCode } from "@src/utils/hooks/useLanguageCode";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import ButtonLink from "../buttonLink";

interface IProps {
    header: { title: string | React.ReactNode; color?: string };
    href: string;
    noButton?: boolean;
}

const HeaderLink: React.FC<IProps> = ({ header: { title, color }, href, noButton }) => {
    const { formatMessageString } = useIntl();
    const languageCode = useLanguageCode();

    if (href.startsWith("http")) {
        return (
            <MuiLink
                sx={{
                    px: 2,
                    py: 3,
                    fontSize: "16px",
                    lineHight: "23.68px",
                    textDecoration: "none",
                    width: "100%",
                    fontWeight: 600,
                    color: color || "common.black",
                    "&:hover": {
                        color: "common.white",
                        backgroundColor: color || "primary.main",
                    },
                }}
                href={href.replace(SVCE_WEBSITE_BASE, `${SVCE_WEBSITE_BASE}/${languageCode}`)}
                target="_blank"
                rel="noopener noreferrer"
            >
                {typeof title === "string" ? formatMessageString({ id: title }) : title}{" "}
                <Launch
                    className="new-window-icon"
                    titleAccess="External Link - Opens New Tab/Window"
                    sx={{
                        fontSize: "1em",
                        fontWeight: "bolder",
                        verticalAlign: "middle",
                    }}
                >
                    <use href="#new-window-icon" />
                    <figcaption>External Link - Opens New Tab/Window</figcaption>
                </Launch>
            </MuiLink>
        );
    } else if (noButton) {
        return (
            <Typography
                component={"span"}
                sx={{
                    color: color || "common.black",
                    fontWeight: "bold",
                    px: { xs: 0, sm: 1, md: 2 },
                    py: 3,
                    fontSize: { xs: "14px", md: "16px" },
                }}
            >
                <RouterLink
                    style={{
                        height: "100%",
                        textDecoration: "none",
                        fontSize: "1em",
                        color: "inherit",
                    }}
                    to={href}
                >
                    {typeof title === "string" ? formatMessageString({ id: title }) : title}
                </RouterLink>
            </Typography>
        );
    } else {
        return (
            <ButtonLink
                variant="outlined"
                to={href}
                sx={{
                    fontSize: "16px",
                    lineHight: "23.68px",
                    color: "common.black",
                    fontWeight: 600,
                    border: 0,
                    borderRadius: 0,
                    px: 2,
                    py: 3,
                    width: "100%",
                    justifyContent: "flex-start",
                }}
            >
                {" "}
                {typeof title === "string" ? formatMessageString({ id: title }) : title}
            </ButtonLink>
        );
    }
};

export default HeaderLink;
