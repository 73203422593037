import { Link, useSearchParams } from "react-router-dom";
import Button from "../button";
import { useLanguageCode } from "@src/utils/hooks/useLanguageCode";

interface InternalLink {
    to?: string;

    // https://reactrouter.com/en/main/components/scroll-restoration#preventing-scroll-reset
    preventScrollReset?: boolean;
}

interface ExternalLink {
    href?: string;
    target?: string;
    rel?: string;
}

type ButtonLinkProps = React.ComponentProps<typeof Button> &
    (InternalLink | ExternalLink) & {
        to?: string | ((languageCode: string) => string);
        queryString?: string;
    };

const ButtonLink: React.FC<ButtonLinkProps> = ({ children, preventScrollReset, ...props }) => {
    const component = "href" in props ? "a" : Link;
    const { sx, ...rest } = props;

    const languageCode = useLanguageCode();
    const [search] = useSearchParams();

    // Necessary "if" to address - 'React does not recognize the `preventScrollReset` prop on a DOM element.'
    if (component === "a") {
        return (
            <Button component={component} sx={{ borderRadius: 2, ...sx }} {...rest}>
                {children}
            </Button>
        );
    } else {
        const qs = "?" + search.toString();
        const { to: _to } = props;
        const to = (() => {
            if (_to) {
                if (typeof _to === "string") {
                    if (_to.includes("?")) {
                        return _to;
                    } else {
                        return `${_to}${qs}`;
                    }
                } else {
                    const __to = _to(languageCode);
                    if (__to.includes("?")) {
                        return __to;
                    } else {
                        return `${__to}${qs}`;
                    }
                }
            } else {
                return null;
            }
        })();

        return (
            <Button
                preventScrollReset={preventScrollReset}
                component={component}
                sx={{ borderRadius: 2, fontWeight: "bold", ...sx }}
                {...rest}
                to={to}
            >
                {children}
            </Button>
        );
    }
};

export default ButtonLink;
