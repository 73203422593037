import { Button as MuiButton, type ButtonProps } from "@mui/material";
import GaTracker from "@src/utils/GaTracker/GaTracker";
import { forwardRef } from "react";

interface Props {
    onClick?: (event: React.MouseEvent) => void;
    label?: string;
}

const Button = forwardRef(
    <C extends React.ElementType>(
        { label, onClick, ...rest }: ButtonProps<C, { component?: C }> & Props,
        ref: React.ForwardedRef<any>,
    ) => {
        return (
            <MuiButton
                {...rest}
                ref={ref}
                title={label}
                onClick={(event) => {
                    GaTracker.trackEvent({
                        category: "Button",
                        action: "Click",
                        label: label,
                    });
                    onClick?.(event);
                }}
            />
        );
    },
);

Button.displayName = "Button";
export default Button;
