import { PopoverOrigin, Typography } from "@mui/material";
import { useIsMobilePhone } from "@src/utils/hooks/useIsMobilePhone";
import React, { useMemo } from "react";
import HeaderLink from "./HeaderLink";
import HeaderPopoverMenu from "./HeaderPopoverMenu";
import { IHeader } from "./types";

interface IProps {
    header: IHeader;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
}

const HeaderMenuItem: React.FC<IProps> = ({ header }) => {
    const isMobilePhone = useIsMobilePhone();

    const title = useMemo(() => {
        if (isMobilePhone && typeof header.title === "string" && header.title.endsWith(".main")) {
            return header.title.replace(".main", ".mobile.main");
        } else {
            return header.title;
        }
    }, [header.title, isMobilePhone]);

    if (header.items && header.items.length === 1) {
        const href = header.items?.[0]?.href;
        return (
            <Typography
                component="div"
                aria-haspopup="false"
                sx={{
                    ...header.sx,
                    cursor: "pointer",
                    fontWeight: "bold",
                    px: { xs: 0, sm: 1, md: 2 },
                    fontSize: { xs: "14px", md: "16px" },
                }}
            >
                {href ? (
                    <HeaderLink header={{ ...header, title }} href={href} noButton />
                ) : (
                    (header.items ?? []).map((i, idx) => (
                        <HeaderMenuItem
                            header={i as IHeader}
                            key={`${header.title}-item-${idx}`}
                            anchorOrigin={{
                                vertical: "center",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "center",
                                horizontal: "right",
                            }}
                        />
                    ))
                )}
            </Typography>
        );
    }

    // https://jcoreio.github.io/material-ui-popup-state/
    return <HeaderPopoverMenu header={header} />;
};

export default HeaderMenuItem;
