import { AppBar, Box, Container, Link as MuiLink, Stack, Toolbar, Typography } from "@mui/material";
import logo from "@src/assets/images/logo.png";
import { SVCE_WEBSITE_BASE, useApplicationRoutes } from "@src/constants/routes";
import { ELanguage } from "@src/types";
import { useIntl } from "@src/utils/hooks/useIntl";
import { useIsMobilePhone } from "@src/utils/hooks/useIsMobilePhone";
import { useLanguageCode } from "@src/utils/hooks/useLanguageCode";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { BOTTOM_TOOLBAR_LINKS } from "./constants/bottom_toolbar_links";
import HeaderMenuItem from "./HeaderMenuItem";
import { MIDDLE_TOOLBAR_LINKS } from "./constants";

const LanguageLink = ({ language, text }: { language: ELanguage; text: string }) => {
    const { pathname } = useLocation();
    const languageCode = useLanguageCode();

    return (
        <RouterLink
            style={{ color: "white", textDecoration: "none" }}
            to={pathname.replace(`/${languageCode}`, `/${language}`)}
        >
            <Typography variant="caption" fontWeight={"bold"}>
                {text}
            </Typography>
        </RouterLink>
    );
};

const Header = () => {
    const { formatMessageString } = useIntl();
    const languageCode = useLanguageCode();
    const routes = useApplicationRoutes();
    const isMobile = useIsMobilePhone();

    return (
        <>
            <AppBar sx={{ backgroundColor: "primary.main" }}>
                <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        py: 1,
                    }}
                >
                    <Stack direction={"row"} spacing={2}>
                        <LanguageLink language={ELanguage.ENGLISH} text={isMobile ? "EN" : "ENGLISH"} />
                        <LanguageLink language={ELanguage.CHINESE} text={"中文"} />
                        <LanguageLink language={ELanguage.SPANISH} text={isMobile ? "ES" : "ESPAÑOL"} />
                        <LanguageLink language={ELanguage.VIETNAMESE} text={"Tiếng Việt"} />
                    </Stack>
                    {!isMobile && (
                        <Stack direction={"row"} spacing={5}>
                            <MuiLink
                                sx={{ color: "common.white", textDecoration: "none" }}
                                target="_blank"
                                href={`${SVCE_WEBSITE_BASE}/${languageCode}/meetings/`}
                                rel="noreferrer noopener"
                            >
                                <Typography variant="caption">
                                    {formatMessageString({ id: "header.top.meetings" })}
                                </Typography>
                            </MuiLink>

                            <MuiLink
                                sx={{ color: "common.white", textDecoration: "none" }}
                                target="_blank"
                                href={`${SVCE_WEBSITE_BASE}/${languageCode}/plans-policies-reports`}
                                rel="noreferrer noopener"
                            >
                                <Typography variant="caption">
                                    {formatMessageString({ id: "header.top.documents" })}
                                </Typography>
                            </MuiLink>
                        </Stack>
                    )}
                </Container>
            </AppBar>
            <AppBar component={"div"}>
                <Toolbar sx={{ width: "100%", py: 2 }}>
                    <Stack
                        direction="row"
                        spacing={3}
                        alignItems={"center"}
                        justifyContent={"space-around"}
                        width={"100%"}
                    >
                        <Box>
                            <MuiLink
                                href={`https://svcleanenergy.org/${languageCode === "en" ? "" : languageCode}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Box
                                    component="img"
                                    src={
                                        isMobile
                                            ? "https://svcleanenergy.org/wp-content/uploads/2018/02/cropped-icon-192x192.png"
                                            : logo
                                    }
                                    alt={formatMessageString({ id: "header.logo" })}
                                    sx={{ width: { xs: "45px", md: "190px" } }}
                                />
                            </MuiLink>
                        </Box>
                        <Box>
                            <Stack direction="row" spacing={3} alignItems={"center"}>
                                {MIDDLE_TOOLBAR_LINKS.map((header, i) => {
                                    return (
                                        <HeaderMenuItem
                                            key={`middle-header-${i}`}
                                            header={{
                                                ...header,
                                                sx: { textTransform: "uppercase", fontWeight: "bold" },
                                            }}
                                        />
                                    );
                                })}
                            </Stack>
                        </Box>
                    </Stack>
                </Toolbar>
            </AppBar>
            <AppBar component={"div"} sx={{ backgroundColor: "blue.main" }}>
                <Toolbar sx={{ width: "100%", py: 3, backgroundColor: "blue.main", pr: { xs: 0, sm: "unset" } }}>
                    <Box>
                        <Stack direction="row" spacing={{ xs: 2, md: 5 }} alignItems={"center"} ml={{ xs: 0, md: 15 }}>
                            {BOTTOM_TOOLBAR_LINKS(routes).map((header, i) => {
                                return (
                                    <HeaderMenuItem
                                        key={`bottom-header-${i}`}
                                        header={{
                                            ...header,
                                            sx: {
                                                color: "common.white",
                                            },
                                        }}
                                    />
                                );
                            })}
                        </Stack>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default Header;
