import type { Charger } from "../types";

export const formatAsThousands = (val: number) =>
    Math.round(val)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const formatAsDollars = (val: number) => `$${formatAsThousands(val)}`;

export const titleCase = (string: string) => {
    if (string === null || string === "") return "";
    const str = string.toString();

    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());
};

export const formatChargerName = ({ make, model, model_variant }: Charger) =>
    `${make} ${model}${model_variant ? " (" + model_variant + ") " : ""}`;

export const formatAsPercent = (val: number, fractionDigits = 0) => {
    return val.toFixed(fractionDigits) + "%";
};

export const formatAsDollarsPerGal = (val: number) => {
    return "$" + val.toFixed(2).toString() + "/gal";
};
