import { Grid2, Typography, TypographyProps } from "@mui/material";
import { Fragment } from "react/jsx-runtime";

const StyledTypography = ({ children, ...rest }: TypographyProps) => {
    return (
        <Typography
            {...rest}
            sx={{
                fontSize: "14px",
                border: "none",
                borderColor: (t) => t.palette.grey["300"],
                padding: "0.25rem 0",
                ...rest.sx,
            }}
        >
            {children}
        </Typography>
    );
};

interface Props {
    facts: {
        title: React.ReactNode | React.ReactNode[];
        value: React.ReactNode | React.ReactNode[];
    }[];
    masterFact: {
        title: React.ReactNode | React.ReactNode[];
        value: React.ReactNode | React.ReactNode[];
    };
}

const FactTable: React.FC<Props> = ({ facts, masterFact }) => {
    return (
        <Grid2 container>
            {facts.map((fact, index) => (
                <Fragment key={`fact-${index}`}>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <StyledTypography component="span">{fact.title}</StyledTypography>
                    </Grid2>
                    <Grid2
                        size={{ xs: 12, md: 6 }}
                        sx={{ textAlign: { xs: "left", md: "right" }, mb: index === facts.length - 1 ? 0 : 1 }}
                    >
                        <StyledTypography component="span" fontWeight={700}>
                            {fact.value}
                        </StyledTypography>
                    </Grid2>
                </Fragment>
            ))}
            {masterFact && (
                <Grid2 container sx={{ borderTop: "1px solid", width: "100%" }} mt={1} pt={1}>
                    <Grid2 key={"master-fact-label"} size={{ xs: 12, md: 6 }}>
                        <StyledTypography
                            component="span"
                            sx={{
                                fontSize: "1rem",
                            }}
                        >
                            {masterFact.title}
                        </StyledTypography>
                    </Grid2>
                    <Grid2 key={"master-fact-value"} size={{ xs: 12, md: 6 }}>
                        <StyledTypography
                            component="span"
                            sx={{
                                textAlign: { xs: "left", md: "right" },
                                fontSize: "1.25rem",
                                fontWeight: 700,
                            }}
                        >
                            {masterFact.value}
                        </StyledTypography>
                    </Grid2>
                </Grid2>
            )}
        </Grid2>
    );
};

export default FactTable;
