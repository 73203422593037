import { Box, styled, type BoxProps, type SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { curvedCorners } from "@src/utils/styling/curvedCorners";
import Footer from "../footer";
import Header from "../header";

const ScreenContainer: React.FC<BoxProps> = styled(Box)(() => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100%",
    width: "100%",
}));

interface Props {
    children: React.ReactNode;
    style?: React.CSSProperties;
    sx?: SxProps<Theme>;
    screenContentSx?: SxProps<Theme>;
}

const Screen: React.FC<Props> = ({ children, sx, screenContentSx, style }) => {
    return (
        <Box height="100%" position="relative" sx={sx} style={style} className="screen-outer">
            <ScreenContainer className="screen-container">
                <Header />
                <Box
                    className="screen-content"
                    sx={{
                        maxWidth: "100%",
                        width: "100%",
                        borderWidth: 15,
                        borderStyle: "solid",
                        borderColor: "primary.main",
                        borderBottomWidth: 100,
                        background: (t) => curvedCorners(t, t.palette.primary.main, t.palette.background.default),
                        backgroundRepeat: "no-repeat",
                        backgroundOrigin: "padding-box",
                        ...screenContentSx,
                    }}
                >
                    {children}
                </Box>
                <Box height={0} flex={1}></Box>
                <Footer />
            </ScreenContainer>
        </Box>
    );
};

export default Screen;
