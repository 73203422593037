import { openDB } from "idb";

// Step 1: Open (or create) a database
const dbName = "svce_ev_finder_db";
const dbVersion = 2; // version of the DB
const dbObjectStoreName = "svce_ev_finder_objects";

const openedDB = openDB(dbName, dbVersion, {
    upgrade(db, _oldVersion, _newVersion, _transaction) {
        db.createObjectStore(dbObjectStoreName);
    },
}).catch(console.error);

async function* withTransation() {
    const db = await openedDB;
    if (!db) return null;

    if (db) {
        const tx = db.transaction(dbObjectStoreName, "readwrite");
        try {
            yield tx;
            tx.commit();
        } catch (error) {
            console.error(error);
            tx.abort();
        }
    } else {
        return null;
    }
}

export const getIndexDBValue = async (key: string) => {
    const tx = (await withTransation().next()).value;
    if (tx) {
        return await tx.objectStore(dbObjectStoreName).get(key);
    } else {
        return null;
    }
};

export const setIndexDBValue = async (key: string, value: any) => {
    const tx = (await withTransation().next()).value;
    if (tx) {
        await tx.objectStore(dbObjectStoreName).put(value, key); // weirdly the value comes first
    } else {
        return null;
    }
};
export const removeIndexDBValue = async (key: string) => {
    const tx = (await withTransation().next()).value;
    if (tx) {
        await tx.objectStore(dbObjectStoreName).delete(key);
    } else {
        return null;
    }
};

const today = new Date();
const minusDays = (minus: number) => {
    const y = new Date(today);
    y.setDate(today.getDate() - minus);
    return y;
};
const getObjectStoreKey = (prefix: string, date: string) => {
    return `${prefix}-${date}`;
};
export const getTodayObjectStoreKey = (prefix: string) =>
    getObjectStoreKey(prefix, today.toLocaleDateString().replaceAll("/", "_"));

export const getPastMonthObjectStoreKeys = (prefix: string) =>
    new Array(30).fill(null).map((_, i) =>
        getObjectStoreKey(
            prefix,
            minusDays(i + 1)
                .toLocaleDateString()
                .replaceAll("/", "_"),
        ),
    );
