import { apiSettings } from "../config/api";
import createCache from "./cache";

const cache = createCache<ReturnType<Response["json"]>>({ ttl: 100 /* ms */ });

export const fetchRecords = async (path: string, params: Record<string, string | null> = {}) => {
    const { url: baseUrl, token } = apiSettings;

    const url = new URL(baseUrl + path);

    // auto-remove null params, for convenience
    const stringParams = Object.fromEntries(
        Object.entries(params).filter((pair): pair is [string, string] => pair[1] !== null),
    );

    const searchParams = new URLSearchParams(stringParams);

    url.search = searchParams.toString();

    const json = await cache.getOrPut(url.toString(), () =>
        window
            .fetch(url.toString(), {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Api-Key": token,
                },
            })
            .then((response) => response.json()),
    );

    if (json.code >= 400) {
        throw new Error(json.message);
    }

    return json;
};
