export default function createCache<T>({ ttl }: { ttl: number }) {
    const cache: Record<
        string,
        {
            content: T;
            expires: Date;
        }
    > = {};

    const put = (path: string, content: T) => {
        clean();
        const expires = new Date();
        expires.setUTCMilliseconds(expires.getUTCMilliseconds() + ttl);
        cache[path] = {
            content,
            expires,
        };
    };

    const get = (path: string) => {
        clean();
        if (path in cache) {
            return cache[path].content;
        } else {
            return null;
        }
    };

    const getOrPut = (path: string, cb: () => T) => {
        const got = get(path);
        if (got) {
            return got;
        } else {
            const made = cb();
            put(path, made);
            return made;
        }
    };

    const clean = () => {
        const now = new Date();
        Object.keys(cache).forEach((path) => {
            if (now > cache[path].expires) {
                delete cache[path];
            }
        });
    };

    return {
        put,
        get,
        getOrPut,
    };
}
