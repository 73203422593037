import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import { Box, Card, type CardProps, Grid2, Stack, Typography } from "@mui/material";
import useFilteredIncentives from "@screens/incentives/hooks/useFilteredIncentives";
import ButtonLink from "@src/components/buttonLink";

import Button from "@src/components/button";
import { ROUTES } from "@src/constants/routes";
import { ElectricVehicle } from "@src/types";
import { formatAsDollars, titleCase } from "@src/utils/formatters";
import { useIsMobilePhone } from "@src/utils/hooks/useIsMobilePhone";
import { useLanguageCode } from "@src/utils/hooks/useLanguageCode";
import { textFit } from "@src/utils/textFit";
import { useIntl } from "@utils/hooks/useIntl";
import { useEffect, useRef } from "react";
import { useVehicleComparisonHandle } from "../../../comparison/hooks/useVehicleComparisonHandle";
import { EV } from "../../hooks/useVehicleList/useVehicleList";
import FactTable from "../factTable";

interface VehicleTileProps extends CardProps {
    vehicle: EV | ElectricVehicle;
    isComparisonBar: boolean;
    currentComparisonHandle?: string;
}

const VehicleTile: React.FC<VehicleTileProps> = ({
    vehicle,
    isComparisonBar,
    currentComparisonHandle: currentComparisonHandle_,
    ...cardProps
}) => {
    const isMobile = useIsMobilePhone();
    const languageCode = useLanguageCode();
    const { formatMessage, formatMessageString } = useIntl();
    const { totalIncentives } = useFilteredIncentives(vehicle.incentives);

    const makeRef = useRef<HTMLDivElement | null>(null);
    const modelRef = useRef<HTMLDivElement | null>(null);
    const driveTrainRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if (makeRef.current) {
            const el = makeRef.current;
            textFit(el, 1 - (42 - el.innerText.length) / 100); // lower length should be compressed less, further from 1
        }
        if (modelRef.current) {
            const el = modelRef.current;
            textFit(el, 1 - (30 - el.innerText.length) / 100);
        }
        if (driveTrainRef.current) {
            const el = driveTrainRef.current;
            textFit(el, 1 - (30 - el.innerText.length) / 100);
        }
    }, []);

    const {
        add: handleAddToCompare,
        remove: handleRemoveFromCompare,
        availableComparisonHandle,
        currentComparisonHandle,
    } = useVehicleComparisonHandle(vehicle, currentComparisonHandle_);

    return (
        <Card
            {...cardProps}
            className="vehicle-tile-card"
            sx={{
                width: "100%",
                maxWidth: "290px",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                height: "100%",
                border: "1px solid",
                borderColor: "grey.100",
                borderRadius: "8px",
                pt: 0,
                pr: 0,
                ...cardProps.sx,
            }}
        >
            <Box position="relative">
                <Grid2 container>
                    <Grid2 size={7} mt={1}>
                        <Typography ref={makeRef} variant="h4" component={"div"} fontWeight={700}>
                            {vehicle.make}
                        </Typography>
                        <Typography ref={modelRef} variant="body1">
                            {vehicle.model}
                        </Typography>
                        {!isComparisonBar ? (
                            <Typography variant="body1">
                                {formatMessage({ id: "vehicle.totalRange" })} {Math.round(vehicle.totalRange)}{" "}
                                {formatMessage({ id: "common.miles" })}
                            </Typography>
                        ) : (
                            !isMobile && (
                                <Typography ref={driveTrainRef} variant="body1">
                                    {titleCase(vehicle.drivetrain)}
                                </Typography>
                            )
                        )}
                    </Grid2>
                    <Grid2 size={{ xs: 0, md: 5 }} sx={{ textAlign: "right", display: { xs: "none", md: "unset" } }}>
                        <Stack direction={"row"} justifyContent={"flex-end"}>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={currentComparisonHandle ? handleRemoveFromCompare : handleAddToCompare}
                                disabled={!currentComparisonHandle && !availableComparisonHandle}
                                sx={{
                                    px: 6,
                                    color: currentComparisonHandle ? "common.white" : "common.black",
                                    backgroundColor: currentComparisonHandle ? "primary.main" : "grey.100",
                                }}
                            >
                                {currentComparisonHandle
                                    ? formatMessageString({ id: "common.remove" })
                                    : formatMessageString({ id: "common.compare" })}{" "}
                                {currentComparisonHandle ? (
                                    <RemoveCircleOutline fontSize="small" sx={{ ml: "1px" }} />
                                ) : (
                                    <AddCircleOutline fontSize="small" sx={{ ml: "1px" }} />
                                )}
                            </Button>
                        </Stack>
                        {!isComparisonBar && (
                            <Box
                                sx={{
                                    height: "1.25rem",
                                    padding: "0 0.375rem",
                                    borderRadius: "0.625rem",
                                    backgroundColor: "grey.900",
                                    fontSize: "0.9375rem",
                                    color: "white.main",
                                    display: "inline-flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    mr: 1,
                                    mt: 1,
                                    svg: {
                                        margin: "0 -0.125rem",
                                    },
                                }}
                            >
                                {vehicle.fuelType === "PHEV" ? (
                                    <Stack direction={"row"} gap={1}>
                                        <ElectricBoltIcon fontSize="inherit" sx={{ color: "yellow.main" }} />
                                        <AddIcon fontSize="inherit" />
                                        <LocalGasStationIcon fontSize="inherit" />
                                    </Stack>
                                ) : (
                                    <ElectricBoltIcon fontSize="inherit" sx={{ color: "yellow.main" }} />
                                )}
                            </Box>
                        )}
                    </Grid2>
                </Grid2>
            </Box>
            {!isComparisonBar && (
                <Box
                    component="img"
                    src={vehicle.images[0].urlThumbnail}
                    alt={vehicle.images[0].altText}
                    sx={{
                        width: "100%",
                        aspectRatio: "4/3",
                        objectFit: "contain",
                        margin: "0 auto",
                        pr: { xs: 2, md: 0 },
                    }}
                />
            )}
            <Box mr={2}>
                <FactTable
                    facts={
                        isComparisonBar
                            ? []
                            : (isMobile
                                  ? []
                                  : [
                                        {
                                            title: formatMessage({ id: "vehicle.totalRange" }),
                                            value: (
                                                <span>
                                                    {Math.round(vehicle.totalRange)}{" "}
                                                    {formatMessage({ id: "common.miles" })}
                                                </span>
                                            ),
                                        },
                                    ]
                              ).concat([
                                  {
                                      title: formatMessage({ id: "screens.vehicleList.electricRange" }),
                                      value: (
                                          <span>
                                              {Math.round(vehicle.electricRange)}{" "}
                                              {formatMessage({ id: "common.miles" })}
                                          </span>
                                      ),
                                  },
                                  {
                                      title: formatMessage({ id: "vehicle.msrp" }),
                                      value: <span>{formatAsDollars(vehicle.msrp)}</span>,
                                  },
                              ])
                    }
                    masterFact={{
                        title: <Typography>{formatMessageString({ id: "vehicle.afterIncentives" })}</Typography>,
                        value: formatAsDollars(vehicle.msrp - totalIncentives),
                    }}
                />
            </Box>
            {!isComparisonBar && (
                <ButtonLink sx={{ mr: 2 }} variant="outlined" to={ROUTES.VehicleDetails(languageCode, vehicle.handle)}>
                    {formatMessage({ id: "screens.vehicleList.detailsButton" })}
                </ButtonLink>
            )}
        </Card>
    );
};

export default VehicleTile;
