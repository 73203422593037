export interface BatteryFactors {
    BMW: number;
    Audi: number;
    Nissan: number;
    Ford: number;
    Chevrolet: number;
    Fiat: number;
    Tesla: number;
    Toyota: number;
    Average: number;
}

export const ELECTRIC_BATTERY_FACTORS = {
    BMW: 0.233,
    Audi: 0.176,
    Nissan: 0.221,
    Ford: 0.198,
    Chevrolet: 0.197,
    Fiat: 0.4,
    Tesla: 0.123,
    Toyota: 0.114,
    Average: 0.201,
} as BatteryFactors;

export const BATTERY_DEGRADATION_FACTORS = [1, 0.98, 0.96, 0.939, 0.919, 0.899, 0.879, 0.877];

export const DOWN_PAYMENT = {
    title: "Down Payment",
    value: 0.1,
    valueAsFormatted: "10% of MSRP + Tax",
};

export const GASOLINE_PRICE_IN_DOLLARS_PER_GAL = {
    title: "Price of Gasoline",
    value: 4.48,
    valueAsFormatted: "$4.48/gal",
    link: "https://gasprices.aaa.com/?state=CA", // November 14, 2024
};

export const ELECTRICITY_RATE_IN_DOLLARS_PER_KWH = {
    title: "Electricity Rate",
    value: 0.33871,
    valueAsFormatted: "$0.33871/kWh",
    link: "https://svcleanenergy.org/wp-content/uploads/SVCE-July-2024-Residential-Rate-Update.pdf",
};

export const SALES_TAX = {
    title: "Sales Tax",
    value: 0.0725,
    valueAsFormatted: "7.25%",
    link: "https://www.caranddriver.com/research/a31548432/california-car-sales-tax-calculator/",
    linkText: "California Sales Tax Rate",
};

export const ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH = {
    title: "Electricity Emissions in CO2 lbs/MWh",
    value: 850,
    valueAsFormatted: "850 lbs/MWh",
};

export const GASOLINE_EMISSIONS_IN_LBS_PER_GAL = {
    title: "Gasoline Emissions in CO2 lbs/gal",
    value: 18.9,
    valueAsFormatted: "18.9 lbs/gal",
    link: "https://www.eia.gov/tools/faqs/faq.php?id=307&t=11",
};

export const EMISSIONS_PER_TREE_IN_LBS_OF_CO2 = {
    title: "Emissions Saved per Tree",
    value: 48,
    valueAsFormatted: "48 lbs of CO2 per tree per year",
    link: "https://www.arborday.org/trees/treefacts/",
};
